import { Balance, parseFigure, PortfolioBalance, Balances } from "./global";
import { Link } from "react-router-dom";
import { useState } from "react";

import { ArrowUp } from "../../svgSymbolsr";

const Banner1 = () =>{
    return(
        <div className="bannerBase ban-bg-1 ban-1">
            <p>Naira Wallet balance</p>
            <p className="ban-figure"> &#x20A6;<Balance/></p>
            <p>
                <button className="brdls bgls"></button>
            </p>
        </div>
    )
}
const Banner2 = () =>{
    const [portfolio, setROI] = useState(Balances.portfolio);
    let dir = portfolio.dir == "proft"? true : portfolio.dir == "loss"?false : null;
    return(
        <Link to="/products" className="bannerBase ban-bg-1 ban-2">
            <svg className="el ell-2" width="266" height="203" viewBox="0 0 266 203" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_930_4586)">
                <ellipse cx="186.681" cy="152.517" rx="86.6813" ry="63.5168" fill="#B4F2E1" fillOpacity="0.48"/>
                </g>
                <defs>
                <filter id="filter0_f_930_4586" x="0" y="-11" width="373.363" height="327.033" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_930_4586"/>
                </filter>
                </defs>
            </svg>
            <p>Your Portfolio</p>
            <p className="ban-figure"> &#x20A6;<PortfolioBalance/></p>
            <p>Holdings Overview</p>
            <p>
                <span>
                    {dir === true && <ArrowUp color="#28DB0B"/>}
                </span>
                <span className={portfolio.dir}>
                    {portfolio.roi}&#x25;
                </span>
            </p>

        </Link>
    )
}
const Banner3 = () =>{
    return(
        <div className="bannerBase ban-bg-1 ban-3">
            <p>
                <Link to="/dashboard/cards">Manage Cards</Link>
                <svg width="64" height="43" viewBox="0 0 64 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="21.2414" cy="21.2414" r="21.2414" fill="#F21616"/>
                    <circle cx="42.7587" cy="21.2414" r="21.2414" fill="#FFE600"/>
                    <circle cx="21.2414" cy="21.2414" r="21.2414" fill="#F21616" fillOpacity="0.51"/>
                </svg>
            </p>
        </div>
    )
}
const Banner4 = () =>{
    return(
        <div className="bannerBase ban-bg-1 ban-4">
            <svg className="el ell-1" width="270" height="145" viewBox="0 0 270 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_930_4587)">
                <circle cx="144.611" cy="-0.389458" r="54.6105" fill="#007DFF"/>
                </g>
                <defs>
                <filter id="filter0_f_930_4587" x="0" y="-145" width="289.221" height="289.221" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="45" result="effect1_foregroundBlur_930_4587"/>
                </filter>
                </defs>
            </svg>
            <svg className="el ell-2" width="266" height="203" viewBox="0 0 266 203" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_930_4586)">
                <ellipse cx="186.681" cy="152.517" rx="86.6813" ry="63.5168" fill="#B4F2E1" fillOpacity="0.48"/>
                </g>
                <defs>
                <filter id="filter0_f_930_4586" x="0" y="-11" width="373.363" height="327.033" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_930_4586"/>
                </filter>
                </defs>
            </svg>


            <p className="head">Vulue FX MTF</p>
            <div className="trend">
                <span>1.3%</span>
                <img src="/static/images/sprites/stocku.png" />
                <span>This Week</span>
            </div>
            <p className="details">
                Providing performing strategies as a reliable plan backed by managing derivatives, and structured similar to a fixed term investment portfolio. Lock-away your investments and <b>earn up to 25%.</b> More suited to investors with short term goals.
            </p>
        </div>
    )
}
export {Banner1, Banner2, Banner3, Banner4};