const ArrowUp = (prop) =>{
    return(
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0L11.1962 9H0.803848L6 0Z" fill={prop.color}/></svg>
    )
}

const PlusIcon = (prop) =>{
    
}



const Lock = (prop) =>{
    return(
        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.4 2.4375V3.75H0.8C0.587827 3.75 0.384344 3.82902 0.234315 3.96967C0.0842855 4.11032 0 4.30109 0 4.5L0 8.25C0 8.44891 0.0842855 8.63968 0.234315 8.78033C0.384344 8.92098 0.587827 9 0.8 9H7.2C7.41217 9 7.61566 8.92098 7.76569 8.78033C7.91571 8.63968 8 8.44891 8 8.25V4.5C8 4.30109 7.91571 4.11032 7.76569 3.96967C7.61566 3.82902 7.41217 3.75 7.2 3.75H6.6V2.4375C6.6 1.79103 6.32607 1.17105 5.83848 0.713927C5.35088 0.256807 4.68956 0 4 0C3.31044 0 2.64912 0.256807 2.16152 0.713927C1.67393 1.17105 1.4 1.79103 1.4 2.4375ZM2.4 3.75V2.4375C2.4 2.03968 2.56857 1.65814 2.86863 1.37684C3.16869 1.09554 3.57565 0.9375 4 0.9375C4.42435 0.9375 4.83131 1.09554 5.13137 1.37684C5.43143 1.65814 5.6 2.03968 5.6 2.4375V3.75H2.4ZM3.2 5.8125C3.20003 5.68257 3.23606 5.55488 3.30457 5.44193C3.37308 5.32898 3.47171 5.23466 3.5908 5.1682C3.70989 5.10174 3.84535 5.06543 3.98391 5.06281C4.12248 5.0602 4.25939 5.09138 4.38123 5.1533C4.50308 5.21521 4.60567 5.30574 4.67897 5.41602C4.75226 5.52629 4.79374 5.65252 4.79935 5.78234C4.80495 5.91217 4.77448 6.04113 4.71093 6.15659C4.64737 6.27205 4.55291 6.37006 4.4368 6.441L4.4336 6.44287C4.4336 6.44287 4.5116 6.88538 4.5996 7.40662V7.407C4.5996 7.48149 4.56804 7.55293 4.51185 7.60561C4.45566 7.65828 4.37946 7.68787 4.3 7.68787H3.6992C3.61974 7.68787 3.54354 7.65828 3.48735 7.60561C3.43116 7.55293 3.3996 7.48149 3.3996 7.407V7.40662L3.5656 6.44287C3.45329 6.37488 3.36092 6.28161 3.29692 6.17157C3.23292 6.06153 3.19932 5.93821 3.1992 5.81288L3.2 5.8125Z" fill={prop.color}/>
        </svg>

    )
}

const MasterCard = () =>{
    return(
        <svg width="64" height="43" viewBox="0 0 64 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21.2414" cy="21.2414" r="21.2414" fill="#F21616"/>
            <circle cx="42.7587" cy="21.2414" r="21.2414" fill="#FFE600"/>
            <circle cx="21.2414" cy="21.2414" r="21.2414" fill="#F21616" fillOpacity="0.51"/>
        </svg>
    )
}
const Visa = () =>{
    return(
        <svg width="57" height="19" viewBox="0 0 57 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.576 1.008L8.016 12.696L13.56 1.008H19.224L10.512 18H3.624L0.912 1.008H6.576ZM25.3367 1.008L22.3367 18H17.0087L20.0087 1.008H25.3367ZM31.6311 18.168C29.5511 18.168 27.8871 17.752 26.6391 16.92C25.4071 16.088 24.7911 14.872 24.7911 13.272C24.7911 13.112 24.8071 12.872 24.8391 12.552H30.5031C30.4551 13.048 30.5271 13.416 30.7191 13.656C30.9271 13.896 31.2631 14.016 31.7271 14.016C32.0151 14.016 32.2551 13.944 32.4471 13.8C32.6391 13.656 32.7351 13.448 32.7351 13.176C32.7351 12.792 32.5431 12.472 32.1591 12.216C31.7911 11.96 31.1911 11.656 30.3591 11.304C29.4471 10.92 28.6951 10.552 28.1031 10.2C27.5271 9.848 27.0231 9.368 26.5911 8.76C26.1591 8.152 25.9431 7.392 25.9431 6.48C25.9431 5.28 26.2391 4.248 26.8311 3.384C27.4391 2.504 28.2791 1.84 29.3511 1.392C30.4231 0.943999 31.6471 0.719999 33.0231 0.719999C34.2551 0.719999 35.3511 0.911999 36.3111 1.296C37.2871 1.68 38.0471 2.24 38.5911 2.976C39.1511 3.696 39.4311 4.552 39.4311 5.544C39.4311 5.672 39.4151 5.896 39.3831 6.216H33.6471C33.6791 6.024 33.6951 5.896 33.6951 5.832C33.6951 5.528 33.6071 5.296 33.4311 5.136C33.2551 4.96 33.0151 4.872 32.7111 4.872C32.4391 4.872 32.2151 4.952 32.0391 5.112C31.8631 5.272 31.7751 5.48 31.7751 5.736C31.7751 6.088 31.9591 6.4 32.3271 6.672C32.6951 6.928 33.2791 7.248 34.0791 7.632C34.9911 8.064 35.7431 8.472 36.3351 8.856C36.9431 9.24 37.4631 9.752 37.8951 10.392C38.3271 11.016 38.5431 11.784 38.5431 12.696C38.5431 13.736 38.2631 14.672 37.7031 15.504C37.1591 16.336 36.3671 16.992 35.3271 17.472C34.2871 17.936 33.0551 18.168 31.6311 18.168ZM50.6775 15.432H45.0135L43.7175 18H38.1255L47.3415 1.008H53.4855L56.6775 18H51.0615L50.6775 15.432ZM50.1015 11.424L49.4055 6.696L47.0295 11.424H50.1015Z" fill="white"/>
        </svg>

    )
}
const Trash = () =>{
    return(
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.71429 0C6.3 0 5.14286 1.15714 5.14286 2.57143H2.57143C1.15714 2.57143 0 3.72857 0 5.14286H18C18 3.72857 16.8429 2.57143 15.4286 2.57143H12.8571C12.8571 1.15714 11.7 0 10.2857 0H7.71429ZM2.57143 7.71429V20.0829C2.57143 20.3657 2.77714 20.5714 3.06 20.5714H14.9657C15.2486 20.5714 15.4543 20.3657 15.4543 20.0829V7.71429H12.8829V16.7143C12.8829 17.4343 12.3171 18 11.5971 18C10.8771 18 10.3114 17.4343 10.3114 16.7143V7.71429H7.74V16.7143C7.74 17.4343 7.17429 18 6.45429 18C5.73429 18 5.16857 17.4343 5.16857 16.7143V7.71429H2.59714H2.57143Z" fill="#646363"/>
        </svg>
    )
}
const Card = (prop) =>{
    return(
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 11.25C0 11.3921 0.0564411 11.5283 0.156907 11.6288C0.257373 11.7293 0.393634 11.7857 0.535714 11.7857H14.4643C14.6064 11.7857 14.7426 11.7293 14.8431 11.6288C14.9436 11.5283 15 11.3921 15 11.25V4.75446H0V11.25ZM2.20982 6.62946C2.20982 6.55842 2.23804 6.49029 2.28828 6.44006C2.33851 6.38983 2.40664 6.36161 2.47768 6.36161H5.55804C5.62908 6.36161 5.69721 6.38983 5.74744 6.44006C5.79767 6.49029 5.82589 6.55842 5.82589 6.62946V8.77232C5.82589 8.84336 5.79767 8.91149 5.74744 8.96173C5.69721 9.01196 5.62908 9.04018 5.55804 9.04018H2.47768C2.40664 9.04018 2.33851 9.01196 2.28828 8.96173C2.23804 8.91149 2.20982 8.84336 2.20982 8.77232V6.62946ZM14.4643 0H0.535714C0.393634 0 0.257373 0.0564413 0.156907 0.156907C0.0564411 0.257373 0 0.393634 0 0.535714V2.74554H15V0.535714C15 0.393634 14.9436 0.257373 14.8431 0.156907C14.7426 0.0564413 14.6064 0 14.4643 0Z" fill={prop.color}/>
        </svg>

    )
}
const AppleLogo = ()=>{
    return(
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.7613 18.125C12.7404 19.1146 11.6259 18.9583 10.5529 18.4896C9.41753 18.0104 8.37586 17.9896 7.17795 18.4896C5.67795 19.1354 4.88628 18.9479 3.99045 18.125C-1.09289 12.8854 -0.342887 4.90625 5.42795 4.61458C6.8342 4.6875 7.81336 5.38542 8.63628 5.44792C9.86545 5.19792 11.0425 4.47917 12.355 4.57292C13.9279 4.69792 15.1154 5.32292 15.8967 6.44792C12.6467 8.39583 13.4175 12.6771 16.3967 13.875C15.8029 15.4375 15.0321 16.9896 13.7509 18.1354L13.7613 18.125ZM8.53211 4.55208C8.37586 2.22917 10.2613 0.3125 12.4279 0.125C12.73 2.8125 9.99045 4.8125 8.53211 4.55208Z" fill="white"/>
        </svg>        
    )
}
const PlayStoreLogo = ()=>{
    return(
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.875 1.32382V16.6754C0.875103 16.7087 0.885037 16.7412 0.903556 16.7689C0.922075 16.7966 0.948355 16.8182 0.979101 16.831C1.00985 16.8438 1.04369 16.8472 1.07638 16.8409C1.10908 16.8346 1.13917 16.8187 1.16289 16.7953L9.15625 8.99999L1.16289 1.2039C1.13917 1.18052 1.10908 1.16466 1.07638 1.15831C1.04369 1.15196 1.00985 1.1554 0.979101 1.16821C0.948355 1.18102 0.922075 1.20262 0.903556 1.2303C0.885037 1.25798 0.875103 1.29052 0.875 1.32382ZM12.5078 5.79687L2.48516 0.274994L2.47891 0.271479C2.30625 0.177729 2.14219 0.411323 2.28359 0.54726L10.1402 8.05976L12.5078 5.79687ZM2.28437 17.4527C2.14219 17.5887 2.30625 17.8223 2.47969 17.7285L2.48594 17.725L12.5078 12.2031L10.1402 9.93945L2.28437 17.4527ZM16.5539 8.02343L13.7551 6.48202L11.1234 8.99999L13.7551 11.5168L16.5539 9.97656C17.3152 9.55585 17.3152 8.44413 16.5539 8.02343Z" fill="white"/>
        </svg>

    )
}

const GoogleAuthLogo = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326667 333333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"><path d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z" fill="#4285f4"/><path d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z" fill="#34a853"/><path d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z" fill="#fbbc04"/><path d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z" fill="#ea4335"/></svg>
    )
}
const AngleRight = (prop) =>{
    return(
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L2.34315 0.928933C1.95262 0.538408 1.31946 0.538408 0.928932 0.928933C0.538407 1.31946 0.538407 1.95262 0.928932 2.34315L6.58579 8L0.928933 13.6569C0.538408 14.0474 0.538408 14.6805 0.928933 15.0711C1.31946 15.4616 1.95262 15.4616 2.34315 15.0711L8.70711 8.70711ZM7 9L8 9L8 7L7 7L7 9Z" fill={prop.color}/>
        </svg>
    )
}
// const Twitter = ()=>{
//     return(
//         <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <g clip-path="url(#clip0_346_4841)">
//             <path d="M18.799 3.77632C18.1073 4.08407 17.364 4.29211 16.5838 4.38562C17.3801 3.90675 17.9917 3.14843 18.2796 2.24487C17.5344 2.6883 16.709 3.01032 15.8305 3.18387C15.127 2.43182 14.1246 1.96191 13.0153 1.96191C10.8852 1.96191 9.15838 3.6944 9.15838 5.83124C9.15838 6.13454 9.19249 6.42982 9.25823 6.71305C6.05285 6.55169 3.21095 5.01122 1.3087 2.67018C0.976712 3.24168 0.786541 3.90635 0.786541 4.61548C0.786541 5.9579 1.46745 7.14226 2.50231 7.83617C1.87009 7.8161 1.2754 7.64201 0.755378 7.35218C0.755107 7.36835 0.755049 7.38452 0.755049 7.40082C0.755049 9.27563 2.0845 10.8396 3.84889 11.195C3.52522 11.2834 3.1845 11.3307 2.83277 11.3307C2.58423 11.3307 2.34263 11.3064 2.10713 11.2613C2.59793 12.7985 4.02227 13.9173 5.70998 13.9485C4.39 14.9863 2.727 15.6049 0.920037 15.6049C0.608722 15.6049 0.301708 15.5866 0 15.5509C1.70684 16.6487 3.73413 17.2893 5.91217 17.2893C13.0063 17.2893 16.8856 11.3932 16.8856 6.27992C16.8856 6.11217 16.8819 5.9453 16.8745 5.77936C17.628 5.2338 18.2819 4.55229 18.799 3.77632Z" fill="#69ACE0"/>
//             </g>
//             <defs>
//             <clipPath id="clip0_346_4841">
//             <rect width="18.799" height="18.8603" fill="white" transform="translate(0 0.195312)"/>
//             </clipPath>
//             </defs>
//         </svg>

//     )
// }

const GreenTickCircle = () =>{
    return(
        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M59.7508 95.1647L35 70.4081L43.2483 62.1597L59.7508 78.6564L92.7442 45.6572L100.998 53.9114L59.7508 95.1647Z" fill="#23D223"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.83203 69.9997C5.83203 34.5622 34.5612 5.83301 69.9987 5.83301C105.436 5.83301 134.165 34.5622 134.165 69.9997C134.165 105.437 105.436 134.166 69.9987 134.166C34.5612 134.166 5.83203 105.437 5.83203 69.9997ZM69.9987 122.5C63.1043 122.5 56.2774 121.142 49.9078 118.503C43.5382 115.865 37.7507 111.998 32.8756 107.123C28.0005 102.248 24.1334 96.4601 21.495 90.0906C18.8566 83.721 17.4987 76.8941 17.4987 69.9997C17.4987 63.1053 18.8566 56.2784 21.495 49.9088C24.1334 43.5392 28.0005 37.7516 32.8756 32.8766C37.7507 28.0015 43.5382 24.1344 49.9078 21.496C56.2774 18.8576 63.1043 17.4997 69.9987 17.4997C83.9226 17.4997 97.2761 23.0309 107.122 32.8766C116.967 42.7222 122.499 56.0758 122.499 69.9997C122.499 83.9235 116.967 97.2771 107.122 107.123C97.2761 116.968 83.9226 122.5 69.9987 122.5Z" fill="#23D223"/>
        </svg>
    )
}

const Calender = (prop) =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z" fill={prop.color}/>
        </svg>

    )
}
export {ArrowUp, Lock, MasterCard, Visa, Trash, Card, AppleLogo, PlayStoreLogo, GoogleAuthLogo, GreenTickCircle, Calender, AngleRight};