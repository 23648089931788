import { useState } from "react";
import { Link } from "react-router-dom";

import SelectWalletBtn from "../selectWalletBtn";
import { OpenInputs } from "../inputs";
import savedAccounts from "../../../savedAcc";


const SavedAcc = (props) =>{
    const data = savedAccounts[props.id];
    return(
        <div className="saved-acc-temp">
            <div className="main">
                <p className="acc-name">{data.name}</p>
                <p className="remn">{data.no}<br/>{data.bank}</p>
            </div> 
            <div className="bank-icn">
                <img src={data.imgUrl} alt="bankLogo" />
            </div>
        </div>
    )
}

const SavedAccountSection = () =>{
    return(
        <>
            <p className="wtd-tf-dir">Use your saved account details</p>
            <div id="saved-details-section">
                <SavedAcc id="1" />
                {/* <SavedAcc id="1" /> */}
            </div>
        </>
    )
}

const EmForm =()=>{
    const [formFull, setForm] = useState(true);
    // const [accNo, setAccNo] = useState('');
    // const [bank, setBank] = useState('');

    // const setAcc = ()=>{

    // }
    return(
        <>
        <SavedAccountSection />
        <form className="em-form-cont">
            {formFull &&<>
            <p>Provide Account Details</p>
            <OpenInputs type="text" placeholder ="Account Name"/>
            <OpenInputs type="text" placeholder ="Bank Name"/></>}
            <OpenInputs type="number" placeholder ="Amount"/>
            <p className="info-txt">Please ensure that the details are correct as any loss of funds will not be covered by vulue group.</p>
            <input type="submit" value="Confirm"/>
        </form>
        </>
    )
}

const WithdrawTfView = () =>{

    return (
        <div id="withdraw-tf-view" className="withdraw-main">
            <p className="prog-head">Withdrawal &lt;&lt; Via Bank Transfer</p>
            <SelectWalletBtn />
            <EmForm />
            <Link to="./" style={{textAlign:"center",width:"100%",display:"block",marginTop:"-1.5em",fontSize:"13px",textDecoration:"underline"}}>Go Back</Link>

            
        </div>
    )
}

export default WithdrawTfView;