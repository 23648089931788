import Header from "../../../views/components/home/header";
import { useParams } from "react-router-dom";
import Bank from './bank';
import Profile from './profile';
import PersonalInfo from "./pInfo";
import {sortRoute} from "../../modules";
import Error from "../../../views/components/error";
import "../../../styles/settings.css"
import Bank1 from "./bank1";
import EmpDev from "./empDet"
import Nok from "./NOK";
import Lang from "./language";
import Password from "./password";
import Otpv from "./OtpV";
import Acount from "./Account";
import AccState from "./AccState";
import PassSec from "./PassSec";
import TranHis from "./TransacHis";
import Plain1 from "./Plain1";

import { useEffect } from "react";
import { emitViewChange, emitScreenViewMobile } from "../../../globals";

const Settings = ()=>{
    useEffect(()=>{
        emitViewChange();
        emitScreenViewMobile();
    },[])
    const {page} = useParams();
    let pages = {
        'profile' : Profile,
        'info' : PersonalInfo,
        'bank': Bank,
        'bank1': Bank1,
        'empDev': EmpDev,
        'nok': Nok,
        'language':Lang,
        'password':Password,
        'otp': Otpv,
        'acc':Acount,
        'accState': AccState,
        'passSec':PassSec,
        'transHis':TranHis,
        'plain1': Plain1
    }

    let Page = sortRoute(page,pages,Error);
    

    return (
        <>
            <Header/>
            <Page/>
        </>

    )
}
export default Settings;