import { useState } from "react";
import { Link } from "react-router-dom";

import NavForOverviewAndListings from "../../../../views/components/app/products/navForOverviewAndListings";
import CallForGroupInvestment from "../../../../views/components/app/products/callForGroup";

import { ArrowUp, Lock } from "../../../../views/components/svgSymbolsr";

const FMSMF = (props) =>{
    const [dur,setDur] = useState(1);
    
    return(
        <div className="listng">
            <div className="head">
                <div className="top">
                    <p className="topic">Forex Market Strategies Mirror Fund <span>1 Unit ongoing</span></p>
                    <div className="prog">
                        <ArrowUp color="#28DB0B"/>
                        <span>Up</span>
                        <span>1.24 &#x25;</span>
                    </div>
                </div>
                <p>Lock-away your investments and earn up to 25&#x25;.</p>
                <p>This is a short term focused fund with an aim at consistently delivering inflation-rate matching returns tied to prevailing strategies mirrored.</p>
            </div>
            <div className="body">
                <div className="amb">
                    <p>Starts from</p>
                    <p>₦50,000</p>
                </div>
                <div className="amb">
                    <p>Starts fromROI (Expected Estimate based on historical performance)</p>
                    <p>6%</p>
                </div>
                <div className="amn">
                    <p>Maturity periods. Choose from 3 to 12 months.</p>
                    <p>
                        <button className={dur == 1 && 'active'} onClick={() => setDur(1)}>3M</button>
                        <button className={dur == 2 && 'active'} onClick={() => setDur(2)}>6M</button>
                        <button className={dur == 3 && 'active'} onClick={() => setDur(3)}>9M</button>
                        <button className={dur == 4 && 'active'} onClick={() => setDur(4)}>12M</button>
                    </p>
                </div>
                <div className="action">
                    { props.login != true && <Link className="link-as-submit login" to="/auth/login">Login To Make Investment</Link>}
                    {props.login == true && <>
                        <Link className="units" to="/products/MTF/units">See Your Units</Link>
                        <Link className="link-as-submit" to="/products/MTF/invest">Invest</Link>
                    </>}

                </div>
            </div>
        </div>
    )
}

const lockedListings = {
    1:{
        topic : "Commodities (Metals) Market Strategies Mirror Fund"
    },
    2:{
        topic : "US Stock Market Strategies Mirror Fund"
    },
    3:{
        topic : "NGX Stock Market Strategies Mirror Fund"
    }
}

const LockedListing = (props) =>{
    const data = lockedListings[props.listingId]
    return(
        <div className="locked-listing">
            <p>{data.topic}</p>
            <Lock color="#FFF" />
        </div>
    )
}

const InfoForUnavailableListing = () =>{
    return(
        <div className="info-for-unav-lstn">
            <i className="fa fa-2xs fa-info-circle"></i>
            <p>The instruments below are currently unavailable. Undergoing rigorous research, refinement and performance testing.</p>
            <Lock color="#FFF" />
        </div>
    )
}

const OtherListings = () =>{
    return(
        <div className="other-listing">
            <div className="mobile-item"><InfoForUnavailableListing/></div>
            <div className="main">
                <LockedListing listingId="1"/>
                <LockedListing listingId="1"/>
                <LockedListing listingId="2"/>
                <LockedListing listingId="3"/>
            </div>
        </div>
    )
}
const Listing = () =>{
    return(
        <div id="main">
            <NavForOverviewAndListings state="1"/>
            <div className="listings-cont">
                <div id="listings-top">
                    <CallForGroupInvestment />
                    <div className="desktop-item">
                        <InfoForUnavailableListing/>
                    </div>
                </div>
                <div id="listings-main">
                    <FMSMF login={true}/>
                    <OtherListings />
                </div>
            </div>
        </div>
    )
}

export default Listing;