import { serverUrl } from "../../config.js";
import store from "../../store/index.js";

const handler = () => {
    const prepareHeaders = (auth = false) => ({
        ...(auth && { Authorization: `Bearer ${store.getState().user.currentToken}` }),
        "Content-Type": "application/json",
    });

    return {
        verification : (payload) => {
            return {
                url: `${serverUrl}/kyc/basic`,
                type: "POST",
                headers: prepareHeaders(true),
                dataType: "json",
                data: payload,
            };
        },
    };
};

export default handler();
