import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";

import "./styles/home.css";

import Landing from "./routes/home/landing";
import About from "./routes/home/about";
import Relations from "./routes/home/relation";

import AuthPage from "./routes/auth/base";
import Dashboard from "./routes/app/dashboard/base";
import Settings from "./routes/app/settings/base";
import Product from "./routes/app/products/MTF/base";
import ProductSplashPage from "./routes/app/products/main";
import { checkAuthState } from "./models/auth/script";
import AppNotification from "./views/components/appNotification";
import Loader from "./views/components/loader";

const Home = () => {
    useEffect(() => {
        const logedState = checkAuthState();
        if (!logedState) window.location.href = "/auth/login";
        else window.location.href = "/dashboard/wallet";
    }, []);
    return <div></div>;
};

const dashboardRouter = createBrowserRouter([
    {
        path: "/",
        element: <Dashboard />,
    },
    {
        path: "/:page",
        element: <Dashboard />,
    },
    {
        path: "/auth/:page",
        element: <AuthPage />,
    },
    {
        path: "/settings/:page",
        element: <Settings />,
    },
]);

const productRouter = createBrowserRouter([
    {
        path: "/MTF/:page",
        element: <Product />,
    },
    {
        path: "/",
        element: <ProductSplashPage />,
    },
]);

const JointDevelopmentRouter = createBrowserRouter([
    {
        path: "/",
        element: <Dashboard />,
    },
    {
        path: "/:page",
        element: <Dashboard />,
    },
    {
        path: "/auth/:page",
        element: <AuthPage />,
    },
    {
        path: "/settings/:page",
        element: <Settings />,
    },
    {
        path: "/MTF/:page",
        element: <Product />,
    },
    {
        path: "/",
        element: <ProductSplashPage />,
    },
]);
let renderRoot = "asmp";


const Initializer = () => {
    const [routeTaken, setRouteTaken] = useState(null);
    useEffect(() => {
        fetch("/config.json")
            .then((response) => response.json())
            .then((data) => {
                if (data.envType == "production") {
                    const hostname = window.location.hostname;
                    const subdomain = hostname.split(".")[0];
                    setRouteTaken(subdomain);
                } else if (data.envType == "development") {
                    setRouteTaken("full");
                }
            })
            .catch((error) => console.error("Error loading config:", error));
    }, []);

    let App;

    switch (routeTaken) {
        case "dashboard":
            App = <RouterProvider router={dashboardRouter} />;
            break;
        case "products":
            App = <RouterProvider router={productRouter} />;
            break;
        case "full":
            App = <RouterProvider router={JointDevelopmentRouter} />;
            break;
        case null:
        default:
            App = <Loader isActive />;
            break;
    }
    return App;
};

ReactDOM.createRoot(document.getElementById(renderRoot)).render(
    <React.StrictMode>
        <Provider store={store}>
            <Initializer />
            <AppNotification />
            <Loader />
        </Provider>
    </React.StrictMode>
);
