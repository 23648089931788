import "../../../styles/settings.css";
import {Link} from 'react-router-dom';
import Input from "../../../views/components/Input";
import PlainInput from "../../../views/components/PlainInput";
import SaveButton from "../../../views/components/SaveButton";


const PersonalInfo = ()=>{
    return(
        <div className="flex flex-col text-center align-items">
            <p style={{fontSize:'24px'}} className="font-bold  text-white pt-50">Personal Info</p>
            <div className="flex flex-col text-center align-items mt-7 font-nobold">
                <p className="text-white px-2-s text-sm flex ">
                    <p>Some fields are locked for editing, Please 
                        <span style={{display:"inline"}}><Link className="text-do text-blue" to={'/'}> Contact us </Link></span>   
                        
                    </p>
                </p>
                <p style={{display:"inline"}} className="text-white px-2 text-sm flex flex-col"> here for assistance.</p>
            </div>
            <Input name={'First Name'} lock={true}/>
            <Input name={'Last Name'} lock={true}/>
            <Input name={'E-mail Address'} lock={false}/>
            <Input name={'Phone Number'} lock={false}/>
            {/* <Input name={'Gender'} lock={false}/> */}
            <PlainInput name={'Gender'} switch={true}/>
            <PlainInput name={'Marital Status'} switch={false}/>
            <Input name={'Number of Children'} lock={true}/>
            <div className="w-full "><p style={{fontSize:'19px', paddingLeft:'35px'}} className="font-bold  text-white pt-50">Address Information</p></div>
            <Input name={'House Address'} lock={true} />
            <SaveButton />
    
        </div>
    )
}
export default PersonalInfo;