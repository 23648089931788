
import React from "react";
import '../../../styles/settings.css';

const Avatar=(props)=>{
return(
    <div className="flex pt-100 text-center  align-items">
    <img src="/static/images/sprites/Avatar1-icon.png"   alt=" Avatar" className="max-w-300" />
    </div>

)
}
export default Avatar;