const emitViewChange = () => {
    const event = new CustomEvent('encxq', { bubbles: true });
    document.dispatchEvent(event);
};

const emitScreenViewWide = () =>{
    const event = new CustomEvent('adb', { bubbles: true});
    document.dispatchEvent(event);
}
const emitScreenViewMobile = () =>{
    const event = new CustomEvent('adx', { bubbles: true});
    document.dispatchEvent(event);
}

export {emitViewChange, emitScreenViewMobile, emitScreenViewWide};