import OrgId from "./home/orgId";
import { Link, useNavigate } from "react-router-dom";

export const Overlay1 = (prop) =>{
    const navig = useNavigate();
    return(
        <div className="auth-lay desktop-item">
            <div className="auth-back-btn-holder">
                {/* <button className="brdls back-btn" onClick={()=>{navig(-1)}} ><i className="fa fa-angle-left fa-lg"></i></button> */}
            </div>
            <div className="content">
                <OrgId />
                <p className="toast">
                    {prop.topic}
                </p>
                <p className="end">{prop.end}</p>
                {prop.redir && <Link to={prop.redir[0]} className="redir-end">{prop.redir[1]}</Link>}
            </div>
        </div>
    )
}

export const Overlay2 = (prop) =>{
    const navig = useNavigate();
    return(
        <div className="auth-lay desktop-item">
            <div className="auth-back-btn-holder">
                {/* <button className="brdls back-btn" onClick={()=>{navig(-1)}}><i className="fa fa-angle-left fa-lg"></i></button> */}
            </div>
            <div className="content">
                <OrgId />
                <p className="ov2-topic">
                    {prop.topic}
                </p>
                {prop.content&& [prop.content]}
            </div>
        </div>
    )
}