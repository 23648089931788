import { Link } from "react-router-dom";
import { useState } from "react";
import { parseFigure } from "./global";

const watchlist = {
    1: {
        name: "AFRICA PRUDUCT",
        tag: "AFRIPUD",
        price: 7.4,
        perc: 0.0,
        dirc: "neut",
    },
    2: {
        name: "MTN NIGERIA",
        tag: "MTNN",
        price: 298.6,
        perc: 3.77,
        dirc: "proft",
    },
    3: {
        name: "FX MTF",
        tag: "FXMTF",
        price: 101243.33,
        perc: 1.24,
        dirc: "proft",
    },
};
const WatchListItem = (props) => {
    const name =
        watchlist[props.id].name.length > 11
            ? watchlist[props.id].name.slice(0, 10) + "..."
            : watchlist[props.id].name;
    const [price, setPrice] = useState(parseFigure(watchlist[props.id].price));
    const [dir, setDir] = useState(watchlist[props.id].dirc);
    const [perc, setPerc] = useState(parseFigure(watchlist[props.id].perc));

    return (
        <div className="watchlist-item">
            <p className="watchlist-topic">{name}</p>
            <p className="watchlist-tag">{watchlist[props.id].tag}</p>
            <p className="watchlist-price">&#x20A6;{price}</p>
            <p className={`watchlist-perc ${dir}`}>{perc} &#x25;</p>
        </div>
    );
};
const RightArrowLink = (props) => {
    return (
        <Link to={props.href}>
            <span>
                <svg
                    width="7"
                    height="10"
                    viewBox="0 0 7 10"
                    fill="#263238"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 9L6 5L1 1"
                        stroke="#263238"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </span>
        </Link>
    );
};

const Watchlist = () => {
    return (
        <div id="watchlist-section">
            <div className="head">
                <p className="head-topic">
                    <span>My Watchlist</span>
                    <RightArrowLink href="/dashboard/market" />
                </p>
                <Link className="head-action">Edit</Link>
            </div>

            <div className="body">
                <WatchListItem id="1" />
                <WatchListItem id="2" />
                <WatchListItem id="3" />
                <WatchListItem id="3" />
                <RightArrowLink href="/dashboard/market" />
            </div>
        </div>
    );
};

export default Watchlist;
