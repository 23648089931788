import { sortRoute } from "../../modules";
import Error from "../../../views/components/error";
import { Card1, Card2 } from "../../../views/components/app/dashboard/cards";
import { Trash } from "../../../views/components/svgSymbolsr";

import { Link } from "react-router-dom";
import { useState } from "react";

import { Card } from "../../../views/components/svgSymbolsr";

const cards = {
    1 : {
        num : "3144**********46",
        name : 'Bright John Peters',
        exp : '12/26',
        vendor : 0
    },
    2 : {
        num : "4160**********70",
        name : 'Bright John Peters',
        exp : '08/26',
        vendor : 1
    }
}


const AnonymCard = () =>{
    return(
        <div className="anonym-card">
            <Card1 txt="Card Holders Name" num="****************" />
            <p className="inf">No saved cards!</p>
        </div>
    )
}
const CardsOwn = () =>{
    let _all = []
    for(let [k,v] of Object.entries(cards)){
        _all.push(
                <div>
                    <Card2 txt={v.name} vendor={v.vendor} num={v.num}/>
                    <p><Link to={`./?actx=rmd&ccdi=${k}`}><Trash/></Link></p>
                </div>
            );
    }
    const data = cards[2];
    return(
        <div className="own-card">
            <div className="tx">
                {_all}
            </div>
        </div>
    )
}
const Own = () =>{

    const [pos, setPos] = useState(Object.keys(cards).length === 0 ? 0 : 1);
    return(
        <div className="manage-cards-cont">
            <p className="head">Manage Cards</p>
            {pos==0 && <AnonymCard />}
            {pos==1&& <CardsOwn/>}
            <div className="add-card-cont">
                <Link className="link-as-submit" to="./?actx=adm">Add Card</Link>
            </div>

        </div>
    )
}

const AddCard = () =>{
    let prevDateInputLength = 0, hasPutSlash = false;
    return(
        <div className="add-card-main">
            <p className="head">Manage Cards &lt;&lt; Add Card</p>
            <div className="new-card-form">
                <div className="cont">
                    <input className="brdls bgls" placeholder="Card Number" maxLength={16}/>
                    <Card color="#02174E"/>
                </div>
                <div className="mid">
                    <div className="cont">
                        <input className="brdls bgls" placeholder="MM/YY" maxLength={5}  onInput={(e)=>{
                            if(e.target.value.length == 2 && prevDateInputLength < e.target.value.length) {
                                e.target.value+="/";
                                hasPutSlash = true;
                            }
                            if(e.target.value.length == 3 && hasPutSlash && !e.target.value.includes('/')){
                                e.target.value = `${e.target.value.slice(0,2)}/${e.target.value.slice(2)}`
                            }
                            prevDateInputLength = e.target.value.length;
                        }}/>
                    </div>
                    <div className="cont">
                        <input className="brdls bgls" placeholder="CVV" maxLength={3}/>
                    </div>
                </div>
                <div className="cont">
                    <input className="brdls bgls" placeholder="Card Pin" maxLength={4}/>
                </div>
            </div>
            <div className="end">
                <Link className="link-as-submit">Confirm</Link>
                <Link className="cancel" to="./">Cancel</Link>
            </div>
        </div>
    )
}

const RemoveCard = () =>{
    const query = new URLSearchParams(window.location.search);
    const ccid = query.get("ccdi");

    const _card = sortRoute(ccid,cards,null)
    return(
        <div className="remove-card-main">
            <p className="head">Manage Cards &lt;&lt; Remove Card</p>
            <div className="display">
                <Card2 txt={_card.name} vendor={_card.vendor} num={_card.num}/>
            </div>
            <p className="quote">Please ensure that you wish to remove this card.</p>
            <div className="end">
                <Link className="link-as-submit">Confirm</Link>
                <Link className="cancel" to="./">Cancel</Link>
            </div>
        </div>
    )
}

const Cards = () => {
    const query = new URLSearchParams(window.location.search);
    const act = query.get("actx");

    let Page;
    if(act){
        const routes = {
            'adm': AddCard,
            'rmd': RemoveCard
        }
        Page = sortRoute(act,routes,Error);
    }
    else{
        Page = Own;
    }

    return (
        <div id="main">
            <Page/>
        </div>
    )
}

export default Cards;