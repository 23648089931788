import requests from "./requests";
import $ from "jquery";

const appControllers = () =>{
    return{
        getDataForVerification : (cb, cbe) => {
            $.ajax(requests.getDataForVerification())
                .done((r) => {
                    const action = cb(r);
                })
                .fail((e) => {
                    const action = cbe(e);
                });
        }
    }
}



export default appControllers()