import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import layoutSlice from "./layoutSlice";

const store = configureStore({
    reducer: {
        user: userSlice,
        layout: layoutSlice
    },
});

export default store;