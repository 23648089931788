import Header from "../../views/components/home/header";
import { AngleRight } from "../../views/components/svgSymbolsr";
import { sortRoute } from "../modules";
import Writ from "../../views/components/home/writ";

import { Link } from "react-router-dom";
import Carousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const IRSplash1 = (prop) =>{
    return(
        <div className={`ir-splash ir-splash-1 ${prop.item}`}>
            <p className="topic">
                Client<br/>Agreement<br/>for Asset<br/>Derivatives<br/>Strategy on
            </p>
            <p className="name">MTPs</p>
            <p className="end">CFDs FX. Stocks. Metals.</p>
            <p className="du">Last Updated 20th, December 2024</p>
        </div>
    )
}

const IRSplash2 = (prop) =>{
    return(
        <div className={`ir-splash ir-splash-2 ${prop.item}`}>
            <p className="topic">
                Client<br/>Agreement<br/>for Asset<br/>Derivatives<br/>Strategy on
            </p>
            <p className="name">Equities<br/>Basket</p>
            <p className="end">CFDs FX. Stocks. Metals.</p>
            <p className="du">Last Updated 20th, December 2024</p>
        </div>
    )
}

const IRFinSplash1 = (prop) =>{
    return(
        <div className={`ir-splash ir-splash-3 ${prop.item}`}>
            <p className="topic">
                Performance<br/>Report for<br/>Asset<br/>Derivatives<br/>Strategy on
            </p>
            <p className="name">FX MTP</p>
            <p className="end">Mar 2024</p>
        </div> 
    )
}


const FAQs = (prop) =>{
    return(
        <div className={`faqs-section ${prop.item}`}>
            <div className="fq-head">
                <p className="fq-topic">FAQs</p>
                <p className="fq-pr">Frequently Asked Questions</p>
            </div>
            <div className="fq-content">
                <Link className={`ir-alt ${prop.lone}`}>
                    <span>General</span>
                    <AngleRight color="#455A64"/>
                </Link>
                <Link className={`ir-alt ${prop.lone}`}>
                    <span>Funding and Withdrawals</span>
                    <AngleRight color="#455A64"/>
                </Link>
                <Link className={`ir-alt ${prop.lone}`}>
                    <span>Group Investing</span>
                    <AngleRight color="#455A64"/>
                </Link>
                <Link className={`ir-alt ${prop.lone}`}>
                    <span>Strategies and Returns</span>
                    <AngleRight color="#455A64"/>
                </Link>
                <Link className={`ir-alt ${prop.lone}`}>
                    <span>Fees and Charges</span>
                    <AngleRight color="#455A64"/>
                </Link>
                <Link className={`ir-alt ${prop.lone}`}>
                    <span>Safety and Security</span>
                    <AngleRight color="#455A64"/>
                </Link>
            </div>
        </div>
    )
}

const FinancialReports = () =>{
    return(
        <>
            <p className="ir-proto-nav">
                <span>Financial Reports</span>
                <Link>See more</Link>
            </p>
            <div className="ir-2-core">
                <IRFinSplash1 item="desktop-item"/>
                <div className="mobile-item irf-splash-cont-m">
                    <Carousel margin={15} items={1}>
                        <IRFinSplash1 item="mobile-item"/>
                        <IRFinSplash1 item="mobile-item"/>
                    </Carousel>
                </div>
            </div>
        </>
    )
}

const InvestorRelation = () =>{
    return(
        <div className="investor-relation">
            <p className="ir-head">Investor Relations</p>
            <div className="ir-content">
                <div className="ir-first">
                    <p className="ir-proto-nav">
                        <span>Legal</span>
                        <Link>See more</Link>
                    </p>
                    <div className="ir-push">
                        <div className="ir-core">
                            <IRSplash1 item="desktop-item"/>
                            <IRSplash2 item="desktop-item"/>
                            <div className="mobile-item ir-splash-cont-m">
                                <Carousel margin={15} items={1}>
                                    <IRSplash1 item="mobile-item"/>
                                    <IRSplash2 item="mobile-item"/>
                                </Carousel>
                            </div>
                        </div>
                        <div className="ir-alt-link"> 
                            <Link className="ir-alt">
                                <span>Terms and Conditions</span>
                                <AngleRight color="#455A64"/>
                            </Link>
                            <Link className="ir-alt">
                                <span>Privacy Policy</span>
                                <AngleRight color="#455A64"/>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="ir-second">
                    <FinancialReports />
                    <div className="ir-faq-cont desktop-item">
                        <p className="head">FAQs</p>
                        <Link className="ir-alt" to="?view=faqs">
                            <span>Frequently Asked Questions</span>
                            <AngleRight color="#455A64"/>
                        </Link>
                    </div>
                </div>
            </div>
            <FAQs item='mobile-item' lone="fq"/>
        </div>
    )
}


const Faq = () =>{
    return(
        <div className="faqs-main">
            <FAQs lone="fq"/>
            <div className="fq-fn desktop-item"><FinancialReports /></div>
        </div>
    )
}



const Relations = () =>{
    const query = new URLSearchParams(window.location.search);
    const view = query.get("view");

    const Page = sortRoute(view,{
        'investorRelations' : InvestorRelation,
        'faqs' : Faq
    }, InvestorRelation)
    return(
        <main id="home-main">
            <Header />
            <Page />
            <footer className="about-footer">
                <Writ />
            </footer>
        </main>
    )
}

export default Relations;