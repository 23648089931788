

const ProgCircle = (prop) =>{                
    return(
        <span className="sd-crc"><span className={`sd-crc-inn ${prop.active?'active':''}`}></span></span>
    )
}

const ProgLine = () =>{
    return(
        <div className="sd-line"></div>
    )
}


const StateDesign = () =>{
    return(
        <div className="state-design">
            <p className="sd-topic">Account Verification</p>
            <div className="sd-content">
                <div className="sd-txt-show">
                    <ProgCircle active={true}/>
                    <p>Provide Basic Information</p>
                </div>
                <ProgLine/>
                <div className="sd-txt-show">
                    <ProgCircle/>
                    <p>Provide Means of Identification</p>
                </div>
                <ProgLine/>
                <div className="sd-txt-show">
                    <ProgCircle/>
                    <p>Face Verification</p>
                </div>
                <ProgLine/>
                <div className="sd-txt-show">
                    <ProgCircle/>
                    <p>Upload Proof of Address</p>
                </div>
            </div>
        </div>
    )
}

export default StateDesign;