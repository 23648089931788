import { Link } from "react-router-dom";
import { copyContent } from "../../../../../routes/modules";
import { OpenInputs } from "../inputs";


const Method = (props) =>{
    return(
        <div className="tf-method">
            <p>Bank Name : <span className="reg">{props.bn}</span></p>
            <p>Account Name : <span className="thin">{props.an}</span></p>
            <p className="no">
                <span>Account Number : <span className="thin">{props.no}</span></span>
                <button className="brdls bgls" onClick={()=>copyContent(props.no)}>
                    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4428 0.958008H2.70598C1.72177 0.958008 0.916504 1.82051 0.916504 2.87467V16.2913H2.70598V2.87467H13.4428V0.958008ZM16.127 4.79134H6.28492C5.30071 4.79134 4.49545 5.65384 4.49545 6.70801V20.1247C4.49545 21.1788 5.30071 22.0413 6.28492 22.0413H16.127C17.1112 22.0413 17.9165 21.1788 17.9165 20.1247V6.70801C17.9165 5.65384 17.1112 4.79134 16.127 4.79134ZM16.127 20.1247H6.28492V6.70801H16.127V20.1247Z" fill="#549DE7"/>
                    </svg>
                </button>
            </p>
        </div>
    )
}

const EmForm =()=>{
    return(
        <form className="em-form-cont">
            <OpenInputs type="text" placeholder ="Account Name"/>
            <OpenInputs type="text" placeholder ="Bank Name"/>
            <OpenInputs type="number" placeholder ="Amount Sent"/>
            <p className="info-txt">Please ensure that the details are correct as any loss of funds will not be covered by vulue group.</p>
            <input type="submit" value="Confirm"/>
        </form>
    )
}
const BankTfView = () =>{
    return(
        <div className="topup-main">
            <div id="bank-tf-view">
                <p className="prog-head">Wallet Funding &lt;&lt; Via Bank Transfer</p>
                <Method bn="Titan Trust" an="Vulue Technologies" no="8394628475" />
                <p className="alter">OR</p>
                <Method bn="Titan Trust" an="Vulue Technologies" no="8394628475" />
                <p className="inst">Provide the sender account details once transfer has been made</p>
                <EmForm />
                <Link to="./" style={{textAlign:"center",width:"100%",display:"block",marginTop:"-1.5em",fontSize:"13px",textDecoration:"underline"}}>Go Back</Link>
                
            </div>
        </div>
    )
}

export default BankTfView;