
import { useState } from "react";

import XenonValidate from "../../noNPM/xenonValidate";


const pc1 = new CustomEvent('pca');
let __GlobalEm



const ResetPassword = () =>{

    const [prg,setPrg] = useState(0);
    
    document.addEventListener('pca',()=>{
        setPrg(1);
    })

    const R1 = () =>{
        const [Ve, setVe] = useState(["",false]);
        return(
            <div>
               <p className="reset-pre">Enter Your account Email Address</p>
               <form method="GET">
                    <div className="field-set">
                        <input className={`auth-inp ${Ve[1]?"auth-inp-v":""}`} type="email" name="email" placeholder="Email" required onChange={(e)=>{if(XenonValidate.validateEmail(e.target.value)[0])setVe([e.target.value,true]); else setVe([e.target.value,false])}}/>
                    </div>
                    <br /><br /><br />
                    <button className="auth-submit" type="submit" disabled={`${Ve[1]?'':"disabled"}`} onClick={e=>{e.preventDefault();console.log(Ve[0])}}>Continue</button>
                </form> 
            </div>
        )
    }

    return(
        <main className="auth-reset w-4/5">
            <p className="auth-head">Reset Password</p>
            {prg == 0 && <R1 />}
        </main>
    )
}

export default ResetPassword;