import LargeButton from '../../../views/components/LargeButton.js';
import Otp from '../../../views/components/app/settings/OtpBox.js';
const Otpv = ()=>{
    return(
        <div className=" pl-4 w-full text-pink-600 text-center   align-items">
           <div className='block w-full  '>
                <p style={{fontSize:'24px'}} className="font-bold pl-7 block w-full text-white  ">OTP Verification</p>
           </div>
           <p className="text-white px-2-s text-sm mx-2 flex ">
                <p>Enter OTP sent to maxjohn@gmail.com </p>
            </p>
             <div className='flex text-center align-items mb-s mt-5 text-white pl-4  gap-4  w-full'>
                 <Otp />
                 <Otp />
                 <Otp />
                 <Otp />
                 <Otp />
                 <Otp />
             </div>
             <LargeButton />

        </div>
    )
}
export default Otpv