import { Link } from "react-router-dom";

import { GreenTickCircle } from "../svgSymbolsr";
import StateDesign from "./stateDesign";


const StateSuccess = (prop) =>{
    return(
        <div className="state-success-cont">
            <p className="ss-head">{prop.head}</p>

            <div className="ss-icon"><GreenTickCircle /></div>
            <div className="ss-action desktop-item">
                <Link className="auth-submit" to={prop.action[1]}>{prop.action[0]}</Link>
            </div>
            <div className="mobile-item ss-mob-alt">
                {prop.prg && <StateDesign />}
                <div>
                    <Link className="auth-submit" to={prop.action[1]}>{prop.action[0]}</Link>
                </div>
            </div>

            
        </div>
    )
}

export default StateSuccess;