import { MasterCard, Visa } from "../../svgSymbolsr";
import { Link } from "react-router-dom";
import { sortRoute } from "../../../../routes/modules";

const Card1 = (props) =>{
    let num="";
    if(props.num){
        for (let i = 0; i < props.num.length; i++) {
            num +=props.num[i];
            if((i+1)%4 == 0) num+=" ";
        }
    }
    return(
        <div className="card1 bannerBase card-c-1"> 
            {props.num && <p className="in">{num}</p>}
            <p>
                
                {props.txt && <p className="out">{props.txt}</p>}
                {props.lnk && <Link className="out" to={props.to}>{props.lnk}</Link>}
                
                <MasterCard />
            </p>

            <svg className="elips1" width="158" height="124" viewBox="0 0 158 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_783_687)">
                <circle cx="96" cy="96" r="96" fill="url(#paint0_radial_783_687)" fillOpacity="0.3"/>
                <circle cx="96" cy="96" r="95.5" stroke="white" stroke-opacity="0.4" style={{'mixBlendMode':"overlay"}}/>
                </g>
                <defs>
                <filter id="filter0_b_783_687" x="-12" y="-12" width="216" height="216" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_783_687"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_783_687" result="shape"/>
                </filter>
                <radialGradient id="paint0_radial_783_687" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(45.7098) scale(268.227 489.44)">
                <stop stop-color="white" stop-opacity="0.7"/>
                <stop offset="1" stop-color="white" stop-opacity="0.2"/>
                </radialGradient>
                </defs>
            </svg>
            <svg className="elips2" width="83" height="50" viewBox="0 0 83 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_783_681)">
                <circle cx="41.5" cy="8.5" r="41.5" fill="url(#paint0_radial_783_681)" fillOpacity="0.3"/>
                <circle cx="41.5" cy="8.5" r="41" stroke="white" stroke-opacity="0.4" style={{'mixBlendMode':"overlay"}}/>
                </g>
                <defs>
                <filter id="filter0_b_783_681" x="-12" y="-45" width="107" height="107" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_783_681"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_783_681" result="shape"/>
                </filter>
                <radialGradient id="paint0_radial_783_681" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 -33) rotate(45.7098) scale(115.952 211.581)">
                <stop stop-color="white" stop-opacity="0.7"/>
                <stop offset="1" stop-color="white" stop-opacity="0.2"/>
                </radialGradient>
                </defs>
            </svg>
            <svg className="elips3" width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_783_685)">
                <circle cx="37" cy="37" r="37" fill="url(#paint0_radial_783_685)" fillOpacity="0.3"/>
                <circle cx="37" cy="37" r="36.5" stroke="white" stroke-opacity="0.4" style={{'mixBlendMode':"overlay"}}/>
                </g>
                <defs>
                <filter id="filter0_b_783_685" x="-12" y="-12" width="98" height="98" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_783_685"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_783_685" result="shape"/>
                </filter>
                <radialGradient id="paint0_radial_783_685" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(45.7098) scale(103.379 188.638)">
                <stop stop-color="white" stop-opacity="0.7"/>
                <stop offset="1" stop-color="white" stop-opacity="0.2"/>
                </radialGradient>
                </defs>
            </svg>
            <svg className="elips4" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_783_689)">
                <circle cx="9.5" cy="9.5" r="9.5" fill="url(#paint0_radial_783_689)" fillOpacity="0.3"/>
                <circle cx="9.5" cy="9.5" r="9.25" stroke="white" stroke-opacity="0.4" stroke-width="0.5" style={{'mixBlendMode':"overlay"}}/>
                </g>
                <defs>
                <filter id="filter0_b_783_689" x="-12" y="-12" width="43" height="43" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_783_689"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_783_689" result="shape"/>
                </filter>
                <radialGradient id="paint0_radial_783_689" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(45.7098) scale(26.5433 48.4342)">
                <stop stop-color="white" stop-opacity="0.7"/>
                <stop offset="1" stop-color="white" stop-opacity="0.2"/>
                </radialGradient>
                </defs>
            </svg>
        </div>
    )
}

const Card2 = (props) =>{
    let num="",Vendor;
    if(props.num){
        for (let i = 0; i < props.num.length; i++) {
            num +=props.num[i];
            if((i+1)%4 == 0) num+=" ";
        }
    }
    if(props.vendor){
        Vendor = sortRoute(props.vendor,{0:MasterCard,1:Visa}, <span>Vendor</span>);
    } else{
        Vendor = MasterCard;
    }
    return(
        <div className="card1 bannerBase card-c-2">
            {props.num && <p className="in">{num}</p>}
            <p>
                
                {props.txt && <p className="out">{props.txt}</p>}
                {props.lnk && <Link className="out" to={props.to}>{props.lnk}</Link>}
                
                <Vendor/>
            </p>

        </div>
    )
}
export {Card1, Card2}