import requests from "./requests";
import $ from "jquery";

import store from "../../store";
import { setCurrentToken } from "../../store/userSlice";

const authControllers = () => {
    return {
        getOtp: (payload, cb, cbe) => {
            $.ajax(requests.getOtp(payload))
                .done((r) => {
                    const action = cb(r);
                })
                .fail((e) => {
                    const action = cbe(e);
                });
        },
        register: (payload, cb, cbe) => {
            $.ajax(requests.register(payload))
                .done((r) => {
                    const token = cb(r);
                    store.dispatch(setCurrentToken({token}));

                })
                .fail((e) => {
                    const action = cbe(e);
                });
        },
    };
};

export default authControllers();
