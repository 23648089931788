
import { Body } from "../../../../views/components/app/dashboard/receipts";
import { Overlay1 } from "../../../../views/components/overlay";
import Error from "../../../../views/components/error";

const redeems = {
    "01234567890123456789" : {
        dir : 0,
        amount : 250000,
        status : 2,
        date : "29 Aug, 2023, 16:31",
        type : "Transfer to bank",
        RDet : {
            name : "UTANGE KEVIN AONDOKATOR",
            bank : "Guaranty Trust Bank",
            no : "0123456789"
        },
        SDet : {
            name : "MAX JOHN",
            bank : "Vulue",
            no : "9876543210"
        },
        sId : "123456789098765432123456789098"
    },
    "01446467892923453394" : {
        dir : 1,
        amount : 0
    }

}

const RedeemToWalletReceipt = () => {
    
}

const MainR = () =>{
    const query = new URLSearchParams(window.location.search);
    const tRef = query.get("tref");
    
    const _trans = redeems[tRef];
    if(_trans){
        
        switch (_trans.dir) {
            case 0:
                return(
                    <Body trans={_trans} id={tRef}/>
                )
                break;
            case 1:
                
                break;
            default:
                return(
                    <Error />
                )
                break;
        }
    } else {
        return(
            <Error />
        )
    }


}

const State = () =>{
    return(
        <div id="auth-base">
            <Overlay1 topic={<>Thanks for<br/>choosing to<br/>grow with<br/>US!</>} redir={['/dashboard/wallet',"Go back to dashboard"]}/>
            <div id="main" className="mtf-redeem-state">
                <MainR />
            </div>
        </div>
    )
}

export default State;