const savedAccounts = {
    1 : {
        name : "Ekwe Yousuf",
        no : "XXXXXXXXXX",
        bank : "Guaranty Trust Bank",
        imgUrl : "/static/images/icons/gt.png"
    }
}









export default savedAccounts;