
const Acount = () =>{
    return(
        <div>
            <p className="text-whitey font-bold pl-6 mb-2">Account Type</p>
            <p className="text-white mt-7 pl-6 ">Individual</p>
             <div className=" flex align-items text-center w-full py-2 "><hr className="w-full-90 t-gray"/></div>   
            <p className="text-white mt-4 pl-6 ">Professional</p>
            <p className="t-gray mx-3 pl-6">This account type is for more advanced clients with greater experience in 
                finance and investing professionally. These accounts are tailored more to affluent clients.</p>
                <div className=" flex align-items text-center w-full py-2 "><hr className="w-full-90 t-gray"/></div>   

        </div>
    )
}
export default Acount;