import StateSuccess from "../../views/components/auth/stateSuccess";


const State = () =>{
    return(
        <main className= "w-4/5 login-main">
            <div>
                <StateSuccess head="Email Verification Successful" action={['Start Verification','/auth/verification']} prg={true}/>
            </div>
        </main>
    )
}

export default State;