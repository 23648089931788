import { useState } from "react"
import { Link } from "react-router-dom"


const topNews = {
    1 : {
        topic : "Crude Oil Inventories & NURPC Report show slowed production",
        imgUrl : "https://www.google.com/url?sa=i&url=https%3A%2F%2Fpicsum.photos%2F&psig=AOvVaw20vjShGTO0tLc3MUIyNeVB&ust=1711875861644000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCMDouvPQm4UDFQAAAAAdAAAAABAE",
        timeUpd : '17 minutes ago',
        date : 'Wed, 02/21',
        time : '20:00'
    },
    2 : {
        topic: "Global Stock Market Surges Following Positive Economic Data",
        imgUrl: "https://example.com/image1.jpg",
        timeUpd: "10 minutes ago",
        date: "Wed, 03/29",
        time: "09:30"
      },
    3 : {
        topic: "Tech Giants Announce Collaborative Effort to Tackle Cybersecurity Threats",
        imgUrl: "https://example.com/image2.jpg",
        timeUpd: "30 minutes ago",
        date: "Wed, 03/29",
        time: "11:45"
      }
}
const topEvent = {
    1 : {
        topic : "Industrial Production (MoM)",
        imgUrl : "",
        tag : "EUR",
        timeUpd: "in 1 hour",
        date: "Wed, 02/21",
        time: "20:00"
    },
    2 : {
        topic : "Industrial Production (MoM)",
        imgUrl : "",
        tag : "GBP",
        timeUpd: "in 1 hour",
        date: "Wed, 02/21",
        time: "20:00"
    },
    3 : {
        topic : "New Loans",
        imgUrl : "",
        tag : "NGN",
        timeUpd: "20 minutes ago",
        date: "Wed, 02/21",
        time: "20:00"
    }
}
const TopNews = (props) =>{
    return(
        <Link to={'./'} className="top-news">
            <div className="banner" style={{backgroundImage: `url(${topNews[props.id].imgUrl}) !important`}}></div>
            <div className="det">
                <p className="topic">{topNews[props.id].topic.length > 53 ? topNews[props.id].topic.slice(0,53)+"..." : topNews[props.id].topic}</p>
                <p className="meta">
                    <span className="timeUpdate">{topNews[props.id].timeUpd}</span>
                    <span className="date">{topNews[props.id].date +"  "+topNews[props.id].time} </span>
                </p>
            </div>
        </Link>
    )
}
const TopEvents = (props) =>{
    return(
        <Link to="./" className="top-event">
            <div className="banner-cont">
                <div className="banner"></div>
                <div className="banner-tag">{topEvent[props.id].tag}</div>
            </div>
            <div className="det">
                <p className="topic">{topEvent[props.id].topic}</p>
                <p className="meta">
                    <span className="date">{topEvent[props.id].date+" "+topEvent[props.id].time}</span>
                    <span className="timeUpdate">{topEvent[props.id].timeUpd}</span>
                </p>
            </div>
        </Link>
    )
}
const Columns = () =>{
    return(
        <>
        Columns
        </>
    )
}

const TopNewsContainer = () =>{
    return(
        <div className="top-news-cont">
            <TopNews  id="1"/>
            <TopNews  id="3"/>
        </div>
    )
}
const TopEventsContainer = () =>{
    return(
        <div className="top-event-cont">
            <TopEvents id="1" />
            <TopEvents id="3" />
        </div>
    )
}
const NewsletterSection = () =>{
    const [focus, setFocus] = useState(1);
    return(
        <div id="newsletter-section">
            <div className="head">
                <p><span>Newsletter</span><Link to="./" className="to-full-cont desktop-item">See more</Link></p>
                <div className="nav">
                    <button className={`brdls bgls ${focus == 1 && 'active'}`} onClick={()=>{setFocus(1)}}>Top News</button>
                    <button className= {`brdls bgls ${focus == 2 && 'active'}`} onClick={()=>{setFocus(2)}}>Top Events</button>
                    <button className= {`brdls bgls ${focus == 3 && 'active'}`} onClick={()=>{setFocus(3)}}>Columns</button>
                </div>
                
            </div>

            <div className="body">
                {focus == 1 && <TopNewsContainer />}
                {focus == 2 && <TopEventsContainer />}
                {focus == 3 && <Columns />}
            </div>
            <Link to="./" className="to-full-cont mobile-item">See more</Link>
        </div>
    )
}

export default NewsletterSection;