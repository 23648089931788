const ongoingUnits = {
    1: {
        status : 1,
        amount : 212654.09,
        ROIToday : 1.24,
        ROI : 2643.94,
        dur : 90,
        Completed : 17,
        unlockDate : "March 31, 2024"
    },
    2 : {
        status : 1,
        amount : 470000,
        ROIToday : 1.32,
        ROI : 2643.94,
        dur : 202,
        Completed : 78,
        unlockDate : "August 16, 2024"
    },
    3 : {
        status : 0,
        amount : 210000,
        dur : 30,
        unlockDate : "Maturity Period: Mar 1, 2024 - May 31, 2024"
    },
    4 : {
        status : 2,
        amount : 225858,
        ROI :15858
    },
    5 : {
        status : 1,
        amount : 400654.09,
        ROIToday : 2.34,
        ROI : 2643.94,
        dur : 30,
        Completed : 20,
        unlockDate : "March 31, 2024"
    }

}

const allTimeUnits = {
    amount : 315953.17,
    earnings : 43643.81
}

export { ongoingUnits, allTimeUnits};