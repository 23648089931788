import { useState } from "react";
const parseFigure = (bal) =>{
    bal = bal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    let [top,end] = bal.toString().split('.')
    return <><span>{top}</span><span>.</span><span>{end}</span></>;
}
const Balances = {
    wallet:{
        value : 3952280.24, 
        curr : "NGN"
    },
    portfolio : {
        value : 212939.54,
        curr : "NGN",
        roi : 2.47,
        dir : 'proft'
    }
}
const Balance = (props) =>{
    const [balance, setBalance] = useState(Balances.wallet);
    
    return(
        <span>{props.hide?<><span></span><span>****</span><span></span></>:parseFigure(balance.value)}</span>
    )
}
const PortfolioBalance = () =>{
    const [balance, setBalance] = useState(Balances.portfolio);
    return(
        <span>{parseFigure(balance.value)}</span>
    )
}

export {
    Balance,
    parseFigure,
    PortfolioBalance,
    Balances
}
