import Input from "../../../views/components/Input";
import PlainInput from "../../../views/components/PlainInput";
import SaveButton from "../../../views/components/SaveButton";

const Nok = ()=>{
    return(
        <div className="flex flex-col text-center align-items">
            <p style={{fontSize:'24px'}} className="font-bold  text-white pt-50">Next of kin</p>
            <div className="w-full special-text mt-5"><p>Next of kin information</p></div>
            <Input name={'Full Name'}/>
            <PlainInput name={'Next of Kin Relationship'} switch={false} />
            <Input name={'Phone Number'}/>
            <div className="w-full special-text mt-4"><p>Next of kin Address</p></div>
            <Input name={'Address'}/>
            <PlainInput name={'Select state'} switch={false} />
            <SaveButton />



        </div>
    )
}
export default Nok;