import { Link } from "react-router-dom";

const SelectBankTfOption = (props) =>{
    return(
        <Link to={props.link} className="fund-method">
            <div className="head">
                <span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.415 2.39457C11.7962 2.27733 12.2038 2.27733 12.585 2.39457L15.571 3.31257C17.1282 3.79133 18.6072 4.4951 19.961 5.40157C21.015 6.10657 20.516 7.74957 19.248 7.74957H4.75196C3.48396 7.74957 2.98496 6.10657 4.03796 5.40157C5.39196 4.49465 6.87127 3.79054 8.42896 3.31157L11.415 2.39457ZM12.145 3.82857C12.0505 3.79931 11.9494 3.79931 11.855 3.82857L8.86996 4.74657C7.68983 5.10904 6.55818 5.61375 5.49996 6.24957H18.5C17.4417 5.61375 16.3101 5.10904 15.13 4.74657L12.145 3.82857Z" fill="white"/>
                        <path d="M4.25 21C4.25 20.8011 4.32902 20.6103 4.46967 20.4697C4.61032 20.329 4.80109 20.25 5 20.25H19C19.1989 20.25 19.3897 20.329 19.5303 20.4697C19.671 20.6103 19.75 20.8011 19.75 21C19.75 21.1989 19.671 21.3897 19.5303 21.5303C19.3897 21.671 19.1989 21.75 19 21.75H5C4.80109 21.75 4.61032 21.671 4.46967 21.5303C4.32902 21.3897 4.25 21.1989 4.25 21ZM6.25 17C6.25 17.1989 6.32902 17.3897 6.46967 17.5303C6.61032 17.671 6.80109 17.75 7 17.75C7.19891 17.75 7.38968 17.671 7.53033 17.5303C7.67098 17.3897 7.75 17.1989 7.75 17V11C7.75 10.8011 7.67098 10.6103 7.53033 10.4697C7.38968 10.329 7.19891 10.25 7 10.25C6.80109 10.25 6.61032 10.329 6.46967 10.4697C6.32902 10.6103 6.25 10.8011 6.25 11V17ZM12 17.75C11.8011 17.75 11.6103 17.671 11.4697 17.5303C11.329 17.3897 11.25 17.1989 11.25 17V11C11.25 10.8011 11.329 10.6103 11.4697 10.4697C11.6103 10.329 11.8011 10.25 12 10.25C12.1989 10.25 12.3897 10.329 12.5303 10.4697C12.671 10.6103 12.75 10.8011 12.75 11V17C12.75 17.1989 12.671 17.3897 12.5303 17.5303C12.3897 17.671 12.1989 17.75 12 17.75ZM16.25 17C16.25 17.1989 16.329 17.3897 16.4697 17.5303C16.6103 17.671 16.8011 17.75 17 17.75C17.1989 17.75 17.3897 17.671 17.5303 17.5303C17.671 17.3897 17.75 17.1989 17.75 17V11C17.75 10.8011 17.671 10.6103 17.5303 10.4697C17.3897 10.329 17.1989 10.25 17 10.25C16.8011 10.25 16.6103 10.329 16.4697 10.4697C16.329 10.6103 16.25 10.8011 16.25 11V17Z" fill="white"/>
                    </svg>
                </span>
                <span className="method-name">Bank Transfer</span>
                <span className="label">RECOMMENDED</span>
            </div>
            <p className="det">Daily cumulative limits for pending KYC and verified KYC for accounts are ₦5,000,000 and ₦25,000,000 respectively.<br /><br/> To fund your Vulue account, make a bank transfer of up to ₦1,000,000 to the accounts below.</p>
        </Link>
    )
}

export default SelectBankTfOption;