import { useSelector } from "react-redux";
import "../../styles/notification.css";

const AppNotification = () => {
    const notificState = useSelector((state) => state.layout.notification);
    return (
        notificState.active && (
            <div className={`app-notification-container ${notificState.type}`}>
                <p className="ap-title">{notificState.title}</p>
                <p className="ap-message">{notificState.message}</p>
            </div>
        )
    );
};

export default AppNotification;
