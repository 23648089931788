import Carousel from 'react-owl-carousel';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { sortRoute } from '../../../../routes/modules';
import { setConfig } from 'dompurify';

const tenors = {
    1 : {
        topic : "Fixed Maturity periods",
        estROI : 6,
        dur : "3 Months",
        status : "proft-bg",
        default : true
    },
    2: {
        topic : "Fixed Maturity periods",
        estROI : 12,
        dur : "6 Months",
        // status : "pend-bg",
        default : false
    },
    3 : {
        topic : "Fixed Maturity periods",
        estROI : 18,
        dur : "9 Months",
        // status : "pend-bg",
        default : false
    },
    4 : {
        topic : "Fixed Maturity periods",
        estROI : 25,
        dur : "12 Months",
        // status : "pend-bg",
        default : false
    }
}
export const Tenor =(props) =>{

    const data = tenors[props.tenorId];
    return(
        <div className='tnrs-main-main'>
            <div className="body">
                <p className="topic">{data.topic}</p>
                <div className="meta">
                    <div className="txt-field">
                        <span>{data.estROI}&#x25;</span>
                        <span>Estimated ROI based on past performance</span>
                    </div>
                    <div className="txt-field">
                        <span>{data.dur}</span>
                        <span>Duration</span>
                    </div>
                    <div className="status">
                        <div><div className={props.active && "proft-bg"}></div></div>
                    </div>
                </div>
                <div className="det">
                    This estimated return rates are derived from averages on past net performances after contractual service deductions in our <a href="" target="_blank">terms of use</a>. They may not reflect nor guarantee future performance.
                </div>
                <p className="tag">{data.default && "Default"}</p>
            </div>
        </div>
        
    )
}

export const Tenors = (props) => {
    const query = new URLSearchParams(window.location.search);
    const tnr = query.get("tnr");


    return(
        <div id="tnrs-sect">
            <p className="head">Chosen Tenor</p>
            <Carousel className="" margin={15} items={props.no}>
                <Link to={props.to[0]}><Tenor tenorId="1" active={true}/></Link>             
                <Link to={props.to[1]}><Tenor tenorId="2" active={false}/></Link>
                <Link to={props.to[2]}><Tenor tenorId="3" active={false}/></Link>
                <Link to={props.to[3]}><Tenor tenorId="4" active={false}/></Link> 
            </Carousel>
        </div>
    )
}

