
import LangDiv from "../../../views/components/LangDiv"
const Lang = ()=>{
    return(
        <div className="flex flex-col  ml-4 ">
            <p style={{fontSize:'24px'}} className="font-bold px-n text-white pt-50">Language</p>

            <LangDiv name={'English'}/>
            <LangDiv name={'English'}/>
            <LangDiv name={'English'}/>
            <LangDiv name={'English'}/>
            <LangDiv name={'English'}/>
            <LangDiv name={'English'}/>
            <LangDiv name={'English'}/>
            <LangDiv name={'English'}/>
        </div>
    )
}
export default Lang