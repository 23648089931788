 import Avatar from "../../../views/components/auth/Avatar";
 import Button from "../../../views/components/Button";
 import "../../../styles/settings.css";
 
 
 const Bank = ()=>{
    return(
        <div className="w-full">
            <Avatar className="max-w-300"/>
           <div>
                <div className="flex flex-col text-center align-items mb-4">
                <p className="text-white mt-m">Ekwe Yeosuf</p>
                <p className="text-white mt-m">client ID: XXXXXXX <span className="ml-2"><img src="/static/images/sprites/copy-icon.png"/></span></p> 
                <p className="text-green font-bold mt-4 ">Verified</p>

                </div>  
                <Button name={'Profile'} link={''} />
                <Button name={'Language'} link={''}/>
                <Button name={'Account Type'} link={''}/>
                <Button name={'Manage Cards'} link={''}/>
                <Button name={'Get Account Statement'} link={''}/>
                <Button name={'Security Settings'} link={''}/>

                <p className="text-white mt-s flex text-center link"><a>Sign Out</a></p>

           </div>


        </div>
    )
 }
 export default Bank;