import {Link} from 'react-router-dom'
import Input from "../../../views/components/Input";
import PassInput from '../../../views/components/PassInput';
import LButton from '../../../views/components/LargeButton';
const Password = ()=>{
    return(
        <div className="flex flex-col text-center align-items">
            <p style={{fontSize:'24px'}} className="font-bold  text-white pt-50">Change Password</p>
            <p className="text-white px-2-s text-sm mt-5 flex ">
                <p>Enter your current password and new password </p>
            </p>
            <Input name={'First Name'} lock={true}/>
            <PassInput name={'Current Password'}/>
            <PassInput name={'New Password'}/>
            <PassInput name={'Confirm Password'}/>
            <LButton  />
        </div>
    )
}
export default Password;