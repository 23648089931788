"use client"
import React, { useState } from "react";
import '../../styles/auth.css'

const Status = () => {
  const [status, setStatus] = useState({nationality: '', country: '', firstname: '', middlename: '', lastname: '', gender: '', date: '', employment: '', home_address: '', bank: '', account_number: ''})

  return (
    <main className="text-white w-4/5">
      <h1 className="header_otp">Verification Status.</h1>
      <div className="dashes">
        <p className="dash"></p>
        <p className="dash"></p>
        <p className="dash"></p>
        <p className="dash"></p>
      </div>
      <p className="text_s">
        All details must match documents provided if not verification will fail.
      </p>

      <form action="" className="grid gap-3">
        <select
          value={status.nationality} onChange={(e) => setStatus({ ...status, nationality: e.target.value })} className="bg-transparent p-2 select_s"
          name="nationality"
          id="nationality"
        >
          <option>Nationality</option>
          <option>Nigerian</option>
          <option>Ghanian</option>
        </select>
        <select
          value={status.country} onChange={(e) => setStatus({...status, country: e.target.value})} className="bg-transparent p-2 select_s"
          name="country"
          id="country"
        >
          <option>Country of Residence</option>
          <option>Nigeria</option>
          <option>Ghana</option>
        </select>
        <input
          value={status.firstname} onChange={(e) => setStatus({...status, firstname: e.target.value.trim()})} className="bg-transparent p-2 input_s"
          type="text"
          placeholder="First Name"
        />
        <input
          value={status.middlename} onChange={(e) => setStatus({...status, middlename: e.target.value.trim()})} className="bg-transparent p-2 input_s"
          type="text"
          placeholder="Middle Name"
        />
        <input
          value={status.lastname} onChange={(e) => setStatus({...status, lastname: e.target.value.trim()})} className="bg-transparent p-2 input_s"
          type="text"
          placeholder="Last Name"
        />
        <select
          value={status.gender} onChange={(e) => setStatus({...status, gender: e.target.value})} className="bg-transparent p-2 select_s"
          name="gender"
          id="gender"
        >
          <option>Gender</option>
          <option>Male</option>
          <option>Female</option>
          <option>Others</option>
        </select>
        <input
          value={status.date} onChange={(e) => setStatus({...status, date: e.target.value})} className="bg-transparent p-2 input_s"
          type="date"
          placeholder="Date of Birth"
        />
        <select
          value={status.employment} onChange={(e) => setStatus({...status, employment: e.target.value})} className="bg-transparent p-2 select_s"
          name="employment"
          id="employment"
        >
          <option>Employment Status</option>
          <option>Employed</option>
          <option>Unemployed</option>
        </select>
        <input type="text" placeholder="Residential Address" value={status.home_address} onChange={(e) => setStatus({...status, home_address: e.target.value.trim()})} className="bg-transparent p-2 input_5" id="i_s" />
        <select
          value={status.bank} onChange={(e) => setStatus({...status, bank: e.target.value})} className="bg-transparent p-2 select_s"
          name="employment"
          id="employment"
        >
          <option>Select your bank you wish to link to vulue</option>
          <option>GT Bank</option>
          <option>first bank</option>
        </select>
        <input type="text" placeholder="Your Bank Account Number" value={status.account_number} onChange={(e) => setStatus({...status, account_number: e.target.value.trim()})} className="bg-transparent p-2 input_5" id="i_s" />
        
        <button className="btn_s" onClick={(e) => { e.preventDefault(); console.log(status) }}>Start Verification</button>
      </form>
    </main>
  );
};

export default Status;
