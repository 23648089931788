import { OpenInputs } from "../inputs"
import { Link } from "react-router-dom";

const EmForm =()=>{
    return(
        <form className="em-form-cont" action="/app/dashboard/topup?method=ussdn">
            <OpenInputs type="text" placeholder ="Bank Name"/>
            <OpenInputs type="number" placeholder ="Amount"/>
            <p className="info-txt">Please ensure that the details are correct as any loss of funds will not be covered by vulue group.</p>
            <Link id="link-as-submit" to="./?method=ussdn">Done</Link>
        </form>
    )
}

const UssdView = () =>{
    return(
        <div className="topup-main">
            <div id="ussd-view">
                <p className="prog-head">Wallet Funding &lt;&lt; USSD Transfer</p>
                <p className="inf-txt">Provide the bank you use and amount and we will generate the USSD.</p>
                <EmForm />
            </div>
        </div>
    )
}
export default UssdView;