import Avatar from "../../../views/components/auth/Avatar";
import Button from "../../../views/components/Button";
import "../../../styles/settings.css";

const Profile = ()=>{
    return(
        <div className="flex flex-col text-center align-items">
            <p style={{fontSize:'24px'}} className="font-bold text-white pt-50">Profile</p>
            <div className="flex pt-100 text-center pb-10 align-items">
                <img src="/static/images/sprites/Avatar1-icon.png"   alt=" Avatar" className="max-w-400" />
            </div>
            <Button name={'Personal details'}  link={'/'}/>
            <Button name={'Bank details'} link={'/'}/>
            <Button name={'Employment details'} link={'/'}/>
            <Button name={'Next of kin'} link={'/'}/>

            
            <div className="flex align-items text-center mt-6 rounded-sm py-3 mx-5 bg-gray">
            <svg className="ml-2" width="15" height="14" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.70082 2.12634C9.66086 2.0793 9.6119 2.04127 9.55702 2.01463C9.50214 1.98798 9.44252 1.9733 9.38188 1.9715C8.43984 1.94611 7.24783 0.90788 6.45971 0.500457C5.97289 0.249572 5.65149 0.0841492 5.41013 0.0405722C5.36112 0.0332118 5.31133 0.0333544 5.26236 0.0409953C5.021 0.0845722 4.6996 0.249995 4.21319 0.50088C3.42508 0.90788 2.23306 1.94611 1.29102 1.9715C1.23034 1.97339 1.17068 1.98812 1.11575 2.01475C1.06082 2.04139 1.01178 2.07937 0.971669 2.12634C0.888537 2.22328 0.845645 2.34998 0.852221 2.47919C1.05458 6.71969 2.53024 9.34403 5.11951 10.8692C5.18683 10.9086 5.26154 10.9289 5.33583 10.9289C5.41013 10.9289 5.48484 10.9086 5.55257 10.8692C8.14184 9.34403 9.61709 6.71969 9.81986 2.47919C9.82682 2.35 9.78404 2.22321 9.70082 2.12634ZM7.64188 3.79411L5.45692 7.11738C5.37852 7.23669 5.25538 7.3158 5.13347 7.3158C5.01115 7.3158 4.87528 7.24684 4.78949 7.15842L3.24939 5.57061C3.19911 5.51859 3.17088 5.44816 3.17088 5.37473C3.17088 5.3013 3.19911 5.23086 3.24939 5.17884L3.6299 4.7858C3.68048 4.73418 3.74878 4.70522 3.81995 4.70522C3.89113 4.70522 3.95943 4.73418 4.01 4.7858L5.01156 5.81811L6.75156 3.17092C6.79182 3.11034 6.8537 3.06864 6.92368 3.05493C6.99367 3.04122 7.06608 3.0566 7.12509 3.09773L7.57046 3.40911C7.6293 3.45054 7.66984 3.51429 7.68322 3.58643C7.6966 3.65856 7.68174 3.73323 7.64188 3.79411Z" fill="white"/>
</svg>
            <div className="w-90 bg-gray text-white ml-2">
            We don’t share your personal details with anyone. This information is required solely for verification.
            </div>

            </div>
        </div>
    )
}
export default Profile;