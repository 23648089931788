"use client"
import React from 'react'
import '../../styles/auth.css'

const Documents = () => {
  return (
    <main className='text-white w-4/5'>
      <h1 className="header_otp">Verification Status.</h1>
      <div className="dashes">
        <p className="dash"></p>
        <p className="dash"></p>
        <p className="dash"></p>
        <p className="dash"></p>
      </div>
      <p className="text_s">
        All details must match documents provided if not verification will fail.
      </p>

      <form action="">
        <select name="id_type" id="id_type" className='select_s bg-transparent p-2'>
            <option></option>
            <option>BVN</option>
        </select>
        <p className='text-center'>Upload Document</p>
      </form>
    </main>
  )
}

export default Documents
