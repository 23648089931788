import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeNotification, openNotification } from "../../store/layoutSlice";

import "../../styles/output.css";
import { useState } from "react";
import XenonValidate from "../../noNPM/xenonValidate";
import { Link } from "react-router-dom";
import { GoogleAuthLogo } from "../../views/components/svgSymbolsr";
import { loginUser } from "../../models/auth/script";

// const dummyLogin = (em,pas) =>{
//     if(em == 'account@vulue.org' && pas == '!24HKA.vulue'){
//         window.location.href = '/dashboard/wallet';
//         return true;
//     } else return false;
// }

const Login = () => {
    const [login, setLogin] = useState({ email: "", password: "" });
    const [pOpen, togglePO] = useState(false);
    const [Ve, setVe] = useState(["", false]);
    const [Vp, setVp] = useState(["", false]);
    const navig = useNavigate();

    const dispatch = useDispatch();
    const notifyUser = (type, title, message) => {
        dispatch(
            openNotification({
                type,
                title,
                message,
            })
        );
        setTimeout(() => {
            dispatch(closeNotification());
        }, 3500);
    };

    return (
        <main className="w-4/5 login-main">
            <h1 className="auth-head">
                Login To Your
                <br />
                Account
            </h1>
            <form action="" method="GET" className="">
                <div className="field-set">
                    <input
                        className={`auth-inp ${Ve[1] ? "auth-inp-v" : ""}`}
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                        onChange={(e) => {
                            if (XenonValidate.validateEmail(e.target.value)[0])
                                setVe([e.target.value, true]);
                            else setVe([e.target.value, false]);
                        }}
                    />
                </div>

                <div className="field-set">
                    <div className="auth-inp-with-icon">
                        <input
                            className={`auth-inp ${Vp[1] ? "auth-inp-v" : ""}`}
                            type={pOpen ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            required
                            onChange={(e) => {
                                if (e.target.value.length > 4)
                                    setVp([e.target.value, true]);
                                else setVp([e.target.value, false]);
                            }}
                        />
                        <button
                            className="auth-inp-icon"
                            onClick={() => {
                                togglePO(pOpen ? false : true);
                            }}
                            type="button"
                        >
                            <i
                                className={`fa ${
                                    pOpen ? "fa-eye-slash" : "fa-eye"
                                }`}
                                style={{ color: "var(--neutral100)" }}
                            ></i>
                        </button>
                    </div>
                </div>

                <Link className="auth-forgot-pass">Forgot Password?</Link>

                <button
                    className="auth-submit"
                    type="submit"
                    disabled={`${Ve[1] && Vp[1] ? "" : "disabled"}`}
                    onClick={(e) => {
                        e.preventDefault();
                        let loginStatus = loginUser(Ve[0], Vp[0]);
                        if (!loginStatus) {
                        } else {
                            navig("/dashboard/wallet");
                        }
                    }}
                >
                    Login
                </button>
            </form>
            <p className="reg-call">
                Don't have an account? <Link to="/auth/register">Sign Up</Link>
            </p>

            <div className="alt-log-mean">
                <span className="line"></span>
                <span>Or</span>
                <span className="line"></span>
            </div>
            <div className="alt-auth-methods">
                <a href="" className="alt-auth">
                    <GoogleAuthLogo />
                    <span>Login with Google</span>
                </a>
            </div>
        </main>
    );
};

export default Login;
