import { createSlice } from "@reduxjs/toolkit";

const layoutSlice = createSlice({
    name: "layout",
    initialState: {
        notification: {
            active: false,
            type: 'success', // 0: success, 1: failure, 2: pending, 3:muted
            title: "Notification",
            message: "This is a test notification",
        },
        loader: {
            active: false
        }
    },
    reducers: {
        openNotification: (state, action) => {
            state.notification ={
                active: true,
                type: action.payload.type,
                title: action.payload.title,
                message: action.payload.message
            }
        },

        closeNotification: (state) => {
            state.notification.active = false;
        },

        openLoader: (state) => {
            state.loader.active = true;
        },
        closeLoader: (state) => {
            state.loader.active = false;
        }
    },
});

export const { openNotification, closeNotification, openLoader, closeLoader } = layoutSlice.actions;

export default layoutSlice.reducer;
