import { Link } from "react-router-dom"

const NavForOverviewAndListings = (props) =>{
    return(
        <div id="ov-and-list-nav">
            <div className={props.state == 0 && `active`}>
                <Link to="/products/MTF/overview" >Overview</Link>
                <span></span>
            </div>
            <div className={props.state == 1 && "active"}>
                <Link to="/products/MTF/listings" >Funds</Link>
                <span></span>
            </div>
        </div>
    )
}

export default NavForOverviewAndListings;