const SelectWalletBtn = () =>{
    return(
        <div id="select-wallet-cont">
            <button className="bgls">
                <span className="curr-id">
                    <span className="logo"></span>
                    <span className="code">NGN</span>
                </span>
                <span className="name">Nigerian Naira</span>
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.833496 0.333008L5.00016 4.49967L9.16683 0.333008H0.833496Z" fill="white"/>
                </svg>
            </button>
        </div>
    )
}

export default SelectWalletBtn;