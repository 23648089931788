import { useState } from "react";

import Search from "../../../views/components/app/settings/Search";
import TransC from "../../../views/components/app/settings/Transac";
import NewPlain from "../../../views/components/app/settings/NewPlain";
const TranHis = () =>{
    const [click, setClick] = useState(false);
    const [click2, setClick2] = useState(false);
    function handleClick(){
        setClick(!click)
    }    
    function handleClick2(){
        setClick2(!click2)
    }
    return (
        <div>
            <div className="flex text-center w-full align-items mt-5 size-t">
                    <p className="text-whitey"> Transaction History</p>
            </div>
            <Search />
            <NewPlain />
            <TransC main={'Fund Investment'} mainV={'-N150,000'} subMain={'August 21, 11:04'} subMainV ={'sucessfull'}/>
            <TransC main={'Wallet Top Up'} mainV={'-N150,000'} subMain={'August 21, 11:04'} subMainV ={'sucessfull'}/>
            <TransC main={'Investment Redeemed'} mainV={'-N150,000'} subMain={'August 21, 11:04'} subMainV ={'sucessfull'}/>
            <TransC main={'Wallet withdrawal'} mainV={'-N150,000'} subMain={'August 21, 11:04'} subMainV ={'sucessfull'}/>
            <TransC main={'Exchange'} mainV={'-N150,000'} subMain={'August 21, 11:04'} subMainV ={'sucessfull'}/>
            <TransC main={'Fund Investment'} mainV={'-N150,000'} subMain={'August 21, 11:04'} subMainV ={'sucessfull'}/>
            <TransC main={'Wallet Top Up'} mainV={'-N150,000'} subMain={'August 21, 11:04'} subMainV ={'sucessfull'}/>
            <TransC main={'Investment Redeemed'} mainV={'-N150,000'} subMain={'August 21, 11:04'} subMainV ={'sucessfull'}/>
            <TransC main={'Fund Investment'} mainV={'-N150,000'} subMain={'August 21, 11:04'} subMainV ={'sucessfull'}/>

        </div>
    )
}
export default TranHis;