import React from 'react'
// import FontAwesome from 'react-fontawesome'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import faStyles from 'font-awesome/css/font-awesome.css'

const PassSec = ()=>{
    return(
        <div>
            <div className="flex w-full mt-5 text-center align-items">
                <p className="text-whitey size-t"> Password and Security</p>
            </div>
            <div className="text-whitey flex text-btw px-2 align-items">
                <p className="size-m">Biometric unlock</p>
                {/* <FontAwesomeIcon icon="fa-regular fa-toggle-on" /> */}
            </div>
            <div className='px-2 w-full mt-5  text-whitey size-m-s'>
                <p className=''>You can set up your fingerprint to grant you access to your account</p>
            </div>
            <div className='text-whitey pl-2 mt-5 w-full-90 '>
                <div className='flex text-btw align-items'>
                    <p className='  '>Change Password</p>

                </div>
                <hr className='w-full ashlike mt-2'/>
            </div>            
            <div className='text-whitey pl-2 mt-5 w-full-90 '>
                <div className='flex text-btw align-items'>
                    <p className='  '>Account Activities</p>

                </div>
                <hr className='w-full ashlike mt-2'/>
            </div>
            
        </div>
    )
}
export default PassSec;