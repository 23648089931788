import requests from "./requests";
import $ from "jquery";

import store from "../../store";

const userControllers = () => {
    return {
        verify: (payload, cb, cbe) => {
            $.ajax(requests.verification(payload))
                .done((r) => {
                    console.log(payload)
                    const action = cb(r);
                })
                .fail((e) => {
                    const action = cbe(e);
                });
        },
    };
};

export default userControllers();
