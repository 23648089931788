import { sortRoute } from "../../modules";

const Market = () =>{
    const query = new URLSearchParams(window.location.search);
    const ccid = query.get("view");
    return(
        <div id="main">
            
        </div>
    )
}

export default Market;