
// import Header from "../../../../views/components/headers";
import Header from "../../../../views/components/home/header";
import { sortRoute } from "../../../modules";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { emitViewChange, emitScreenViewMobile } from "../../../../globals";
import "../../../../styles/products.css";

import Overview from "./overview";
import Listing from "./listings";
import Units from "./units";
import OngoingUnitDetails from "./ongoinUnits";
import Redeem from "./redeem";
import State from "./state-redeem-to-wallet";
import Invest from "./invest";

import Error from "../../../../views/components/error";


const Product = () =>{
    useEffect(()=>{
        emitViewChange();
        emitScreenViewMobile();
    },[])
    const { page } = useParams();
    let routes = {
        'overview': Overview,
        'listings': Listing,
        'units' : Units,
        'details' : OngoingUnitDetails,
        'redeem' : Redeem,
        'state' : State,
        'invest' : Invest
    };

    let Page = sortRoute(page, routes, Error);
    let addHeader = false;

    
    if(page == 'overview'||page == 'listings'||page == 'units'||page == 'details') addHeader = true;
    return(
        <>
            { addHeader && <Header notification={true} />}
            <Page />
            { addHeader && <div id="disclmr-sect">
                <p>DISCLAIMER</p>
                <p>The information provided on this platform is intended solely to demonstrate the types of offerings available on the Vulue platform and is subject to change in future. Notwithstanding the proper and reasonable care that has been exercised in the preparation of this report, no responsibility or liability is accepted by Vulue, its employees nor its affiliates for any error, omission or opinion expressed or implied here. This strategy description is not intended to serve as an investment or research recommendation and should not be regarded as such.</p>
                <p>Investing involves risks, including illiquidity and the loss of capital, and should be done with full acknowledgement that historical returns or expected returns are hypothetical in nature and may not reflect actual future performance of strategies provided. Please read our Client Agreements. Note that strategies, unlike deposits are not insured by NDIC before investing.</p>
            </div>}
        </>
    )
}

export default Product;