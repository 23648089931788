import { Link, NavLink } from "react-router-dom";
import OrgId from "./orgId";
import NotificBtn from "../notificbtn";
import $ from 'jquery';
import { useState } from "react";

// const loggedIn = false;
// if(window.clientUser){
//     loggedIn = true;
// };

const showMobileNav = ()=>{
    $('#home-nav').css('display','flex');
}
const closeMobileNav = ()=>{
    $('#home-nav').css('display','none');
}


// const OrgId = () => {
//     return (
//         <span id="orgId">
//             <img src="/static/images/icons/logoBase.png" alt="" />
//             <span>VULUE</span>
//         </span>
//     );
// };

// alert(window.innerWidth);
const Nav = (prop) =>{
    let _det = {
        name : 'User'   
    }
    let loggedIn = prop.logged || false
    if(loggedIn){
        _det = {
            name : JSON.parse(sessionStorage.getItem('userProfile')).name 
        }
    }

    

    return(
        <div>
            <nav id="home-nav">
                <div id="nav-close-cont"><button className="brdls bgls mobile-open-nav" onClick={()=>{closeMobileNav()}}><i className="fa fa-xmark fa-lg"></i></button></div>
                <div className="mobile-item mobile-nav-user">
                   {loggedIn && <div className="mobile-nav-user-cont">
                        <Link className="user-avatar"></Link>
                        <div className="nav-user-message">
                            <p>Good Morning</p>
                            <p>{_det.name}</p>
                        </div>
                    </div>}
                </div>
                {!loggedIn &&<NavLink to="/">Home</NavLink>}
                {loggedIn && <NavLink to="/dashboard/wallet">Dashboard</NavLink>}
                <NavLink to="/products">Our Products</NavLink>
                <NavLink to="/relations?view=investorRelations" >Investor Relations</NavLink>
                {loggedIn && <NavLink to="/">Settings</NavLink>}
                <NavLink to="/">Contact Us</NavLink>
                <div className="mobile-item mobile-login-cont">
                    {
                        !loggedIn&&<><Link className="" to="/auth/login">Login</Link><Link className="" to="/auth/register">Register</Link>
                        </>
                        }
                    {
                        loggedIn && <Link className="" >Logout</Link>
                    }
                </div>
                <button id="select-language">
                    
                </button>
                <div id="user-container">
                    
                    { loggedIn && <><Link to="" className="user-logged-in-avatar"><p className="user-avatar"></p></Link>
                    <Link className="residue">Log out</Link></>}

                    { !loggedIn && <><p><i className="fa fa-user fa-2x"></i></p>
                    <Link className="residue" to="/auth/login">Login</Link>
                    <Link className="residue" to="/auth/register">Register</Link>
                    </>}
                </div>
            </nav>
            <div style={{display:'flex',gap:'1em', alignItems:'center'}}>
                {loggedIn && <NotificBtn />}
                <button className="brdls bgls mobile-open-nav" onClick={()=>{showMobileNav()}}><i className="fa fa-bars fa-lg"></i></button>
            </div>
        </div>
    )
}



const Header = () =>{
    let logged = sessionStorage.getItem('userProfile')?true:false;
    return(
        <><header id="home-header">
            <OrgId />
            <Nav logged={logged || false}/>
            
        </header><div id="under-header"></div></>
    )
}
export default Header;