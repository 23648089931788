import React from "react";
import '../../styles/settings.css';


const Input = (props)=>{
    return(
        <div className="w-full flex align-items mt-4 text-center"> 
            <fieldset style={{width:"80%"}} className=" block flex   align-items text-center rounded-sm">
                <legend className="text-white ml-2 h-2">{props.name}</legend>
                <input style={{width:"90%"}}  className="h-2 transparent text-white  border-n text-4"/>{props.lock&&<svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.30858 4.33333V6.66667H1.34132C0.999273 6.66667 0.671237 6.80714 0.429375 7.05719C0.187513 7.30724 0.0516357 7.64638 0.0516357 8L0.0516357 14.6667C0.0516357 15.0203 0.187513 15.3594 0.429375 15.6095C0.671237 15.8595 0.999273 16 1.34132 16H11.6588C12.0008 16 12.3289 15.8595 12.5707 15.6095C12.8126 15.3594 12.9485 15.0203 12.9485 14.6667V8C12.9485 7.64638 12.8126 7.30724 12.5707 7.05719C12.3289 6.80714 12.0008 6.66667 11.6588 6.66667H10.6915V4.33333C10.6915 3.18406 10.2499 2.08186 9.46387 1.2692C8.67781 0.456546 7.6117 0 6.50005 0C5.3884 0 4.32229 0.456546 3.53623 1.2692C2.75018 2.08186 2.30858 3.18406 2.30858 4.33333ZM3.92068 6.66667V4.33333C3.92068 3.62609 4.19244 2.94781 4.67616 2.44772C5.15989 1.94762 5.81596 1.66667 6.50005 1.66667C7.18414 1.66667 7.84021 1.94762 8.32394 2.44772C8.80766 2.94781 9.07941 3.62609 9.07941 4.33333V6.66667H3.92068ZM5.21037 10.3333C5.21041 10.1023 5.2685 9.87534 5.37895 9.67454C5.48939 9.47375 5.64839 9.30606 5.84037 9.18791C6.03236 9.06976 6.25074 9.0052 6.47412 9.00056C6.69749 8.99591 6.91821 9.05134 7.11463 9.16142C7.31106 9.27149 7.47645 9.43243 7.59461 9.62847C7.71277 9.82451 7.77965 10.0489 7.78868 10.2797C7.79771 10.5105 7.74859 10.7398 7.64613 10.9451C7.54368 11.1503 7.3914 11.3245 7.20422 11.4507L7.19906 11.454C7.19906 11.454 7.3248 12.2407 7.46667 13.1673V13.168C7.46667 13.3004 7.41578 13.4274 7.3252 13.5211C7.23463 13.6147 7.11178 13.6673 6.98368 13.6673H6.01513C5.88703 13.6673 5.76418 13.6147 5.67361 13.5211C5.58303 13.4274 5.53214 13.3004 5.53214 13.168V13.1673L5.79975 11.454C5.61869 11.3331 5.46978 11.1673 5.36661 10.9717C5.26343 10.776 5.20928 10.5568 5.20908 10.334L5.21037 10.3333Z" fill="#455A64"/>
</svg>}

            </fieldset>
        </div>
    )

}
export default Input;