import { useState } from "react";


import { ongoingUnits, allTimeUnits } from "./data";
import { parseFigure } from "../../../../views/components/app/dashboard/global";
import { OngoingUnit, ProcessingUnit, CompletedUnit } from "../../../../views/components/app/products/mtfProgressUnits";



const AllTimeUnit = () =>{
    if(Object.keys(allTimeUnits).length !== 0){
        return(
            <div id="all-time-units" className="mtf-all-time-unit-main">
                <p><span>Summary</span><span>All time</span></p>
                <p>Foreign Exchange (FX) Market Strategies Mirror Fund</p>
                <div className="mtf-all-time-analytics">
                    <p>Total Locked</p>
                    <p>&#x20A6;{parseFigure(allTimeUnits.amount)}</p>
                    <p>Total earned <span className="proft">+{parseFigure(allTimeUnits.earnings)}</span></p>
                </div>
                <img src="/static/images/sprites/mtf.png"/>

            </div>
        )
    }
    else{
        return(
            <div id="all-time-unit">
               
            </div>
        )
    }
} 

const unitSelect = (status)=> {
    let Slect;
    switch (status) {
        case 0:
            Slect = ProcessingUnit;
            break;
        case 1:
            Slect = OngoingUnit;
            break;
        case 2:
            Slect = CompletedUnit;
            break;
    }
    let Set = [];
    for (const [k, v] of Object.entries(ongoingUnits)){
        if(v.status == status) Set.push(<Slect id={k}/>)
    }
    return Set;
}


const Ongoing = () =>{
    return(
        <>
       {unitSelect(1)}
       </>
    )
}

const Processing = () =>{
    return(
        <>
       {unitSelect(0)}
       </>
    )
}

const Completed = () =>{
    return(
        <>
       {unitSelect(2)}
       </>
    )
}

const ActiveUnit = () => {
    const [selection , setSelect] = useState(1);
    return(
        <div className="mtf-active-units-cont">
            <div className="nav">
                <button className={selection == 1 && "active"} onClick={() => setSelect(1)}>Ongoing</button>
                <button className={selection == 0 && "active"} onClick={() => setSelect(0)}>Pending</button>
                <button className={selection == 2 && "active"} onClick={() => setSelect(2)}>Completed</button>
            </div>
            <div className="mtf-units-main">
                {selection == 1 && <Ongoing />}
                {selection == 0 && <Processing />}
                {selection == 2 && <Completed />}
            </div>
        </div>
    )
}


const Units = () =>{

    return(
        <div id="main" className="mtf-unit-main">
            <p className="head">MY MTPs</p>
            <div className="units-all">
                <AllTimeUnit />
                <ActiveUnit/>
            </div>
            
        </div>
    )
}
export default Units;