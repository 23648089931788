const NotificBtn = (prop) =>{
    return(
        <button className={`brdls bgls ${!prop.desktop?'mobile-item':''} notific`}>
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.45833 19.3747H8.45817C8.05871 19.3748 7.67397 19.2239 7.38107 18.9523C7.18433 18.7699 7.03879 18.542 6.95535 18.2913H12.0442C11.9735 18.5041 11.8577 18.7013 11.7026 18.8686C11.4332 19.1591 11.0648 19.338 10.6701 19.3701L10.5333 19.3747H8.45833ZM16.2917 11.2955V11.4135L16.3444 11.5191L18.2424 15.3149L18.2424 15.315C18.2891 15.4083 18.3124 15.5116 18.3105 15.616C18.3085 15.7203 18.2813 15.8226 18.2311 15.9142C18.181 16.0057 18.1094 16.0837 18.0225 16.1416C17.9387 16.1973 17.8431 16.2327 17.7433 16.2449L17.6515 16.2497H1.33541H1.33526C1.23085 16.2497 1.12799 16.2244 1.03549 16.176C0.942991 16.1276 0.863616 16.0574 0.804165 15.9716C0.74471 15.8858 0.706954 15.7868 0.69413 15.6832C0.681775 15.5833 0.692915 15.4821 0.726594 15.3874L0.763298 15.3036L2.65554 11.5191L2.70833 11.4135V11.2955V7.37467C2.70833 5.57341 3.42388 3.84593 4.69756 2.57224C5.97125 1.29856 7.69873 0.583008 9.49999 0.583008C11.261 0.582979 12.9531 1.26692 14.2195 2.49057C15.4848 3.7132 16.2262 5.37914 16.2876 7.13746L16.2917 7.37888V11.2955Z" stroke="#D9D9D9"/>
            </svg>
        </button>
    );
}

export default NotificBtn;