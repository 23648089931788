import { sortRoute } from "../../modules";

import WithdrawTfView from "../../../views/components/app/dashboard/withdraw/tf";
import State from "../../../views/components/app/dashboard/withdraw/state";

import Error from "../../../views/components/error";

import SelectBankTfOption from "../../../views/components/app/dashboard/selectBankTf";
import SelectWalletBtn from "../../../views/components/app/dashboard/selectWalletBtn";
import { Overlay1 } from "../../../views/components/overlay";


const Own = () =>{
    return(
        <div className="withdraw-main">       
            <p className="action-head">Withdrawal</p>
            <SelectWalletBtn />
            <SelectBankTfOption link=".?prg=tf"/>
        </div>

    )
}


const Withdraw = () => {
    const query = new URLSearchParams(window.location.search);
    const prg = query.get("prg");

    const routes = {
        null : Own,
        'tf' : WithdrawTfView,
        'state' : State
    }

    const Page = sortRoute(prg,routes,Error);

    return (
        <div id="auth-base" className="">
            <Overlay1 topic={<>Thanks for<br/>choosing to<br/>grow with<br/>US!</>} redir={['/dashboard/wallet','Go back to dashboard']}/>
            <Page />
        </div>
    )
}
export default Withdraw;