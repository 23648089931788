import Input from "../../../views/components/Input";
import { Link } from "react-router-dom";

const Bank1 = ()=>{
    return(
        <div className="flex flex-col text-center align-items">
            <p style={{fontSize:'24px'}} className="font-bold  text-white pt-50">Bank</p>
            <div className="flex flex-col text-4l text-center align-items mt-7 font-nobold mb-2">
                <p className="text-white text-4l px-2 flex flex-col">
                    <p>Some fields are locked for editing, Please </p>
                    <p className="para"><Link to ={'/'}>Contact us</Link>  here for assistance.</p>
                </p>
               
            </div>
            <Input name={'Bank verification Number'} lock={true} />
        </div>
    )
}
export default Bank1 ;