import { serverUrl } from "../../config.js";
import store from "../../store/index.js";


const handler = () => {
    const prepareHeaders = (auth = false) => ({
        ...(auth && { Authorization: `Bearer ${store.getState().user.currentToken}` }),
        "Content-Type": "application/json",
    });

    const prepareData = (payload) => {
        const data = new FormData();
        for (const [k, v] in Object.entries(payload)) {
            data.append(k, v);
        }
        return data;
    };

    return {
        getDataForVerification: () => {
            return {
                url: `${serverUrl}/extras/verification1`,
                type: "GET",
                dataType: "json",
            };
        },
    };
};

export default handler();
