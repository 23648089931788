import { serverUrl } from "../../config.js";

const handler = () => {
    const prepareHeaders = (token = null) => ({
        ...(token && { Authorization: `Bearer ${token}` }),
        "Content-Type": "application/json",
    });

    const prepareData = (payload) => {
        const data = new FormData();
        for (const [k, v] in Object.entries(payload)) {
            data.append(k, v);
        }
        return data;
    };

    return {
        getOtp: (payload) => {
            return {
                url: `${serverUrl}/auth/generate-otp`,
                type: "POST",
                dataType: "json",
                data: payload,
            };
        },
        register: (payload) => {
            return {
                url: `${serverUrl}/auth/new-user`,
                type: "POST",
                dataType: 'json',
                data: payload,
            }
        }
    };
};

export default handler();
