import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import XenonValidate from "../../noNPM/xenonValidate";
import $ from 'jquery';
import { Calender } from "../../views/components/svgSymbolsr";

import appController from "../../models/app/controller"; 
import userController from "../../models/user/controller";
import { openNotification, closeNotification, openLoader, closeLoader } from "../../store/layoutSlice";

import InLoader from "../../views/components/inLoader";




const Verification = () => {

    const dispatch = useDispatch();

    const [verifProgress, setProgress] = useState(1);
    const [inLoaderActive, setinLoaderActive] = useState(false);

    const [nationality,_Nationality] = useState(['',false]);
    const [country,_Country] = useState(['',false]);
    // const [fn,_fn] = useState(['',false]);
    const [mn,_mn] = useState(['',false]);
    // const [ln,_ln] = useState(['',false]);
    const [gen,_gen] = useState(['',false]);
    const [dob,_dob] = useState(['',false]);
    const [emp,_emp] = useState(['',false]);
    const [marital,_marital] = useState(['',false]);
    const [bn,_bn] = useState(['',false]);
    const [ba,_ba] = useState(['',false]);
    const [soi,_soi] = useState(['',false]);


    let SubmitDisabledLogic = true ||(nationality[1] && country[1] && gen[1] && dob[1] && emp[1] && marital[1] && bn[1] && ba[1] && soi[1])


    const notifyUser = (type, title, message) => {
        dispatch(
            openNotification({
                type:type,
                title: title,
                message: message,
            })
        );
        setTimeout(() => {
            dispatch(closeNotification());
        }, 3500);
    };

    const [countries,setCountries] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [genders, setGenders] = useState([]);
    const [employmentTypes, setEmploymentTypes] = useState([]);
    const [maritalStatuses, setMaritalStatuses] = useState([]);
    const [banks, setBanks] = useState([]);
    

    useEffect(()=>{
        dispatch(openLoader());
        appController.getDataForVerification(
                (r)=>{
                if(r.status == 0){

                    const countries = [];
                    const nationalities = [];
                    const genders = [];
                    const employmentTypes = [];
                    const maritalStatus = [];
                    const banks = [];

                    for(const [k,v] of Object.entries(r.data.countries)){
                        countries.push(<option value={k} className="bg-secondary border-none" key={k}>{v.name}</option>);
                        nationalities.push(<option value={k} className="bg-secondary border-none" key={k}>{v.nationality}</option>);
                    }
                    for(const [k,v] of Object.entries(r.data.genders)){
                        genders.push(<option value={k} className="bg-secondary border-none" key={k}>{v}</option>);
                    }
                    for(const [k,v] of Object.entries(r.data.employment)){
                        employmentTypes.push(<option value={k} className="bg-secondary border-none" key={k}>{v.full_name}</option>);
                    }
                    for(const [k,v] of Object.entries(r.data.maritalStatus)){
                        maritalStatus.push(<option value={k} className="bg-secondary border-none" key={k}>{v}</option>);
                    }
                    r.data.banks.forEach((d,i) => {
                        banks.push(<option value={`${d.code}/${d.type}`} className="bg-secondary border-none" key={i}>{d.name}</option>);
                    })
                    
                    setCountries(countries);
                    setNationalities(nationalities);
                    setGenders(genders);
                    setEmploymentTypes(employmentTypes);
                    setMaritalStatuses(maritalStatus);
                    setBanks(banks);

                } else {
                    notifyUser('error','Unknown Error','An error occured, please try again');
                }


                dispatch(closeLoader());
            },
            (e)=>{
                console.log(e);
                dispatch(closeLoader());
            }
        )
    },[]);

const verify = () =>{
    setinLoaderActive(true);
    userController.verify(
        {

        },
        (r)=>{
            console.log(r)
            setProgress(2);
            setinLoaderActive(false)
        },
        (e)=>{
            console.log(e)
            setinLoaderActive(false)
        }
    )
}

let Verif1 = <div>
        <form>
            <div className="field-set">
                <select className={`auth-inp ${nationality[1]?"auth-inp-v":""}`} onChange={(e)=>{ if(e.target.value == "")_Nationality([e.target.value,false]); else _Nationality([e.target.value,true]);}}>
                    <option value="" className="bg-secondary border-none">Nationality</option>
                    {nationalities}
                </select>
            </div>
            <div className="field-set">
                <select className={`auth-inp ${country[1]?"auth-inp-v":""}`} onChange={(e)=>{ if(e.target.value == "")_Country([e.target.value,false]); else _Country([e.target.value,true]);}}>
                    <option value="" className="bg-secondary border-none">Country Of Resisdence</option>
                    {countries}
                </select>
            </div>
            <div className="field-set">
                <input className={`auth-inp ${mn[1]?"auth-inp-v":""}`} placeholder="Middle Name (Optional)" onChange={(e)=>{if(XenonValidate.validateName(e.target.value)[0])_mn([e.target.value,true]); else _mn([e.target.value,false])}}/>
            </div>
            <div className="field-set">
                <select className={`auth-inp ${gen[1]?"auth-inp-v":""}`} onChange={(e)=>{ if(e.target.value == "")_gen([e.target.value,false]); else _gen([e.target.value,true]);}}>
                    <option value="" className="bg-secondary border-none">Gender</option>
                    {genders}
                </select>
            </div>
            <div className="field-set">
                <div className="auth-inp-with-icon">
                    <input className={`auth-inp ${dob[1]?"auth-inp-v":""}`} placeholder="Date of birth" type="text" id="dob-select" required onChange={(e)=>{if(e.target.value)_dob([e.target.value,true]); else _dob([e.target.value,false])}} onFocus={()=>{document.getElementById('dob-select').type='date'}}/>
                    <button className="auth-inp-icon" onClick={()=>{$('#dob-select').focus();document.getElementById('dob-select').showPicker()}} type="button"><Calender color="#FFF"/></button>
                </div>
            </div>
            <div className="field-set">
                <select className={`auth-inp ${emp[1]?"auth-inp-v":""}`} onChange={(e)=>{ if(e.target.value == "")_emp([e.target.value,false]); else _emp([e.target.value,true]);}}>
                    <option value="" className="bg-secondary border-none">Employment Status</option>
                    {employmentTypes}
                </select>
            </div>
            <div className="field-set">
                <select className={`auth-inp ${marital[1]?"auth-inp-v":""}`} onChange={(e)=>{ if(e.target.value == "")_marital([e.target.value,false]); else _marital([e.target.value,true]);}}>
                    <option value="" className="bg-secondary border-none">Marital Status</option>
                    {maritalStatuses}
                </select>
            </div>
            <div className="field-set">
                <select className={`auth-inp ${bn[1]?"auth-inp-v":""}`} onChange={(e)=>{ if(e.target.value == "")_bn([e.target.value,false]); else _bn([e.target.value,true]);}}>
                    <option value="" className="bg-secondary border-none">Select your bank you wish to link to Vulue</option>
                    {banks}
                </select>
            </div>
            <div className="field-set">
                <input className={`custom-verification-inp bgls ${ba[1]?"custom-verification-inp-v":""}`} placeholder="Your Bank Account Number" type="number" onChange={(e)=>{if(e.target.value.trim().length===10)_ba([e.target.value.trim(),true]); else _ba([e.target.value.trim(),false]) }} maxLength="10"/>
            </div>
            <div className="field-set">
                <input className={`custom-verification-inp bgls ${soi[1]?"custom-verification-inp-v":""}`} placeholder="State your source(s) of funds e.g. Inheritance, Salary, Savings, Rental Income, Trading" type="text" onChange={(e)=>{if(XenonValidate.validateName(e.target.value)[0])_soi([e.target.value,true]); else _soi([e.target.value,false]) }}/>
            </div>

            
            <button className="auth-submit" type="button" disabled={`${SubmitDisabledLogic?'':"disabled"}`} onClick={(e)=>{
                    verify();
                }}> {inLoaderActive? <InLoader /> : "Continue"}
            </button>
        </form>
    </div>


  return (
    <main className="w-4/5 login-main">
        <div className='verif-head'>
            <p className='verif-topic'>Provide basic information</p>
            <div className='verif-prog-cont'>
                <span className={`verif-prog ${verifProgress>0?'active':''}`}></span>
                <span className={`verif-prog ${verifProgress>1?'active':''}`}></span>
                <span className={`verif-prog ${verifProgress>2?'active':''}`}></span>
                <span className={`verif-prog ${verifProgress>3?'active':''}`}></span>
            </div>
            <p className="verif-pre">All details must match documents provided if not verification will fail.</p>
        </div>
        {verifProgress === 1 && Verif1}

    </main>
  )
}

export default Verification
