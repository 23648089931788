import '../../styles/settings.css';

const SaveButton = ()=>{
    return(
        <div className='w-full-80 mt-6 flex text-btw align-items'>
            <p className='text-white save'>Save edited changes</p>
            <button className='rounded-sm p-1 btn-blue text-white'>Save <span className="block save">Changes</span> </button>
        </div>
    )
}
export default SaveButton;