import { Link } from "react-router-dom";

const UssdViewN = () =>{
    return(
        <div id="ussd-view-n" className="topup-main">
            <p className="prog-head">Wallet Funding &lt;&lt; USSD Transfer &lt;&lt; USSD</p>
            <p className="inf-txt">&#x2A;122&#x2A;78#</p>
            <Link id="link-as-submit" to="./?method=state&txid=67912964bfu82y367912">Done</Link>
            <Link to="./" style={{textAlign:"center",width:"100%",display:"block",marginTop:"-1.5em",fontSize:"13px",textDecoration:"underline"}}>Cancel Transaction</Link>
        </div>
    )
}
export default UssdViewN;