

import NavForOverviewAndListings from "../../../../views/components/app/products/navForOverviewAndListings";
import OvGr1 from "../../../../views/components/app/products/overviewGraph1";
import CallForGroupInvestment from "../../../../views/components/app/products/callForGroup";
import {Tenors} from "../../../../views/components/app/products/tenors-carousel";

import { Balance } from "../../../../views/components/app/dashboard/global";
import { Link } from "react-router-dom";
import { useState } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const paneNavItems = {
    1 : {
        name : "Forex Market Strategies Mirror Fund",
        icon : ""
    },
    2 : {
        name : "NGX Stock Market Strategies Mirror Fund",
        icon : ""
    }
}

const AboutMTF = () =>{
    const [full, setToFull] = useState(false);
    return(
        <>
            <p className="about-mtf">
                Providing performing strategies as a reliable plan backed by managing derivatives, and structured similar to a fixed term investment portfolio. Lock-away your investments and earn up to 25%. <br/> <br/> Our “Forex Market Strategies Mirror Fund” is a set-up curated by Vulue's derivatives trading team, operating as a strategy provider and incorporating our team's best performing managed strategies on FX Derivatives.   <br/><br/>Powered by mirroring trades of our expert securities traders onto an investments pool held with reputable brokerages we've linked to your Vulue account, through the process called strategy mirroring for the purpose of portfolio management. <br/> <br/> The forex or currency market is a place where people can buy and sell different currencies from around the world. The market is open 24 hours a day, five days a week, and it is the largest and most liquid market in the world (With a daily turnover of $6.6 Trillion as of 2020). The market is not centralized, but rather consists of a network of banks, brokers, and other financial institutions that trade currencies electronically. The price of one currency in terms of another is called the exchange rate, and it changes constantly based on supply and demand, economic and political factors, and speculation.
                {!full && <button className="brdls bgls see-more" onClick={() => setToFull(true) }>See more</button>}

            </p>
            {full && <p className="about-mtf">  
                A CFD is a financial instrument that allows our investors to speculate on the price movements of an underlying asset without owning the asset itself. Forex CFDs enable investors to trade in the forex market with leverage, which means they can control a larger position with a smaller amount of capital. This allows them to trade in the forex market without the high capital requirements of traditional currency trading. The funds with these accounts may be held and traded in retail brokerage accounts linked to your Vulue Account.<br/> <br/> As a strategy provider, Vulue provides an end-to-end solution for our clients-curating and offering its reliable strategies across markets.<br/> <br/> Associated risk controlled CFDs trading strategy for sustainable gains made possible by our derivatives trading team's shared extensive years of discretionary trading with conservative leverage and targets, expansion of research, performance testing of our unique edge. Smart money-centric analysis fuelled by macroeconomics, price action, and proprietary data indicators selection form the cornerstone of our strategy. <br/> <br/>As a strategy provider, Vulue takes away the hassle of at times straining processes involved in finding a legitimate brokerage, getting to use it, and manage your trading account, with just a few steps and a single transaction.
                <div><button className="brdls bgls see-less" onClick={() => setToFull(false) }>See Less</button></div>
            </p>}
            
        </>
    )
}

const StrategyBenefit = () =>{
    return(
        <div className="strategy-bnft">
            <p>
                Providing performing strategies as a reliable plan backed by managing derivatives, and structured similar to a fixed term investment portfolio . Lock-away your investments and earn up to 25%. <br/><br/>This is a short term focused strategy with an aim at consistently delivering inflation-rate matching returns tied to prevailing strategies mirrored.   
            </p>
            <br/>
            <ul>
                <li>Capital growth based</li>
                <li>Short term horizon &#x2212; minimum of 3 months to 12 months with funds locked until end of the tenor </li>
                <li>Competitive rate of return</li>
                <li>Invest from  &#x20A6;50,000.00 to  &#x20A6;10 million</li>
            </ul>
        </div>
    )
}

const AboutMain = () =>{
    const [view, setView] = useState(0);
    return(
        <div id="about-mtf-main">
            <div className="nav">
                <button className={`brdls bgls ${view == 0 && 'active'}`} onClick={() => setView(0)} >About</button>
                <button className={`brdls bgls ${view == 1 && 'active'}`} onClick={() => setView(1)} >Stategy Benefits</button>
            </div>
            <div className="body">
                {view == 0 && <AboutMTF />}
                {view == 1 && <StrategyBenefit />}
            </div>
        </div>
    )
}


const MTFHistoricPerformanceCalc = () =>{
    return(
        <div id="mtf-hist-calc">
            <p className="head">Put FX MTF's Historic Performance Into Perspective</p>
            <p className="meta">This is a visualisation of this strategy's performance for the past 12 months. It doubles as a profitability calculator.</p>
            <OvGr1 />
        </div>
    )
}
const OvInv = () =>{
    return(
        <div className="ov-inv">
            <Link className="link-as-submit" to="/products/MTF/invest">Start Investing</Link>
            <p>Min. ₦50,000-max. ₦10,000,000 investment allowed at a time in the Mirror Fund. <span>₦<Balance /> - NGN Wallet</span></p>
        </div>
    )
}


const Overview =()=>{
    return(
        <div id="main">
            <NavForOverviewAndListings state="0"/>
            <div className="mobile-item">
                <OvGr1 />
                <OvInv />
                <CallForGroupInvestment />
                <AboutMain />
                <Tenors no={1} to={["/products/MTF/invest?tnr=1","/products/MTF/invest?tnr=2","/products/MTF/invest?tnr=3","/products/MTF/invest?tnr=4"]}/>
                <MTFHistoricPerformanceCalc />
            </div>
            <div className="mtf-ov-cont desktop-item">
                <section>
                    <OvGr1 />
                    <div className="ov-inv-in">
                        <OvInv />
                        <div>
                            <CallForGroupInvestment />
                        </div>
                    </div>
                    <Tenors no={2} to={["/products/MTF/invest?tnr=1","/products/MTF/invest?tnr=2","/products/MTF/invest?tnr=3","/products/MTF/invest?tnr=4"]}/>
                </section>
                <aside>
                    <AboutMain />
                    <MTFHistoricPerformanceCalc />
                </aside>
            </div>
            
        </div>
    )
}
export default Overview;