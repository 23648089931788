import {useState} from 'react'

const NewPlain = ()=>{
    const [click, setClick] = useState(false);
    const [click2, setClick2] = useState(false);
    function handleClick(){
        setClick(!click)
    }    
    function handleClick2(){
        setClick2(!click2)
    }
    return(
        <div  className="flex text-btw  w-full align-items text-whitey  mt-5 px-2">
        <div onClick={handleClick}  className="flex text-center align-items">
            <p className="mr-1">All categories</p> 
            {click ? 
            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.60356 7.13281L8.93369 0.382812H0.273438L4.60356 7.13281Z" fill="#455A64"/>
            </svg>
            : 
            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.60356 0.382812L8.93369 7.13281H0.273438L4.60356 0.382812Z" fill="#455A64"/>
            </svg>
            }
        </div>
        <div onClick={handleClick2} className="flex text-center align-items">
            <p className="mr-1">Any status</p> 
            {click2 ? 
            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.60356 7.13281L8.93369 0.382812H0.273438L4.60356 7.13281Z" fill="#455A64"/>
            </svg>
            : 
            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.60356 0.382812L8.93369 7.13281H0.273438L4.60356 0.382812Z" fill="#455A64"/>
            </svg>
            }
        </div>

    </div>

    )
}
export default NewPlain;