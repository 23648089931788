import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openNotification, closeNotification, openLoader, closeLoader } from "../../store/layoutSlice";

import XenonValidate from "../../noNPM/xenonValidate";

import authController from '../../models/auth/controller';

import "../../styles/output.css";
import InLoader from "../../views/components/inLoader";


const Register = () => {
    const navig = useNavigate()

    const [regProgress, regPrg] = useState(false);

    const [inLoaderActive, setinLoaderActive] = useState(false);

    const [Vty, setTy] = useState([false,""]);
    const [Ve, setVe] = useState([false,""]);
    const [Vp, setVp] = useState([false,""]);
    const [Vrp, setVrp] = useState([false,""]);
    const [pOpen,togglePO] = useState(false);
    const [rpOpen,togglerPO] = useState(false);

    const [fn,_fn] = useState(['',false]);
    const [ln,_ln] = useState(['',false]);

    const [agr, agree] = useState(false);

    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [counter, setCounter] = useState("1:00");
    const [reOtpDisabled, reOtpDisabled_] = useState(true);

    const [token, setToken] = useState('');

    useEffect(()=>{
        if(otp.includes('')) return
        validateOtp();
    },[otp])

    const handleChange = (e, index) => {
        if(isNaN(e.target.value.trim())) return false ;
        setOtp([...otp.map((data, i) => (i === index) ? e.target.value.trim() : data)]);
        if (e.target.value.trim() && index < 5) {
            e.target.nextSibling.focus()
        }
        if (e.target.value.trim() === '' && index > 0) {
            e.target.previousSibling.focus();
        }
    }
    const startCountDown = () => {
        reOtpDisabled_(true);
        let initTime = 60;
        let counting = setInterval(() => {
            if (initTime > 0) {
                --initTime;
                if (initTime > 9) setCounter(`0:${initTime}`);
                else setCounter(`0:0${initTime}`)
            } else {
                clearInterval(counting);
                reOtpDisabled_(false);
            }
        }, 1000)
    }

    const dispatch = useDispatch();
    const notifyUser = (type, title, message) => {
        dispatch(
            openNotification({
                type:type,
                title: title,
                message: message,
            })
        );
        setTimeout(() => {
            dispatch(closeNotification());
        }, 3500);
    };
    
    const getOtp = () =>{
        setinLoaderActive(true);
        authController.getOtp(
            {email: Ve[1]},
            (r) => {
                setinLoaderActive(false);
                switch (r.status) {
                    case 0:
                        setToken(r.token);
                        if(!regProgress) regPrg(true);
                        startCountDown();
                        break;
                    case 1:
                    case 2:
                    case 3:
                        notifyUser('error','',r.message);
                        break;
                    default:
                        notifyUser('error','Unknown Error','An error occured, please try again');
                        break;
                }
            },
            (e) => {
                setinLoaderActive(false);
                notifyUser('error','Unknown Error','An error occured, please try again');
                console.log(e)
            }
        )
    }

    const validateOtp = ()=>{
        dispatch(openLoader());
        authController.register({
            email: Ve[1],
            pass: Vp[1],
            cpass: Vrp[1],
            type: Vty[1],
            otp: otp.join(''),
            token: token,
            first_name: fn[0],
            last_name: ln[0]
        },
        (r)=>{
            dispatch(closeLoader());
            console.log(r);
            switch (r.status) {
                case 0:
                    
                    notifyUser('success','',r.message);
                    setTimeout(()=>navig('/auth/state'),1000);
                    return r.token;
                    break;
                default:
                    if(r.status) notifyUser('error','',r.message);
                    else notifyUser('error','Unknown Error','An error occured, please try again');
                    return null;
                    break;
            }
        },
        (e)=>{
            dispatch(closeLoader());
            console.log(e);
            notifyUser('error','Unknown Error','An error occured, please try again');
            return null;
        })
    }
    
    return (
        <main className="w-4/5">
            {!regProgress && <><h1 className="auth-head">Create A Free<br/>Account</h1>
            <form action="" method="post" className="">
                <div className="field-set">
                    <select name="account-type" id="account-type" value={Vty[1]} className={`auth-inp ${Vty[0]&&"auth-inp-v"}`} onChange={(e)=>{ if(e.target.value == "")setTy([false,e.target.value]); else setTy([true,e.target.value]);}}>
                        <option value="" className="bg-secondary border-none">Account Type</option>
                        <option value={0} className="bg-secondary border-none">Individual</option>
                        <option value={1} className="bg-secondary border-none">Professional</option>
                    </select>
                </div>
                {Vty[1] == 1 &&<p className="text-xs text-white">This account type is for more advanced clients with greater experience in finance and investing professionally. These accounts are tailored more to affluent clients.</p>}
                <div className="field-set">
                    <input className={`auth-inp ${Ve[0]?"auth-inp-v":""}`} type="email" name="email" placeholder="Email" value={Ve[1]} onChange={(e)=>{if(XenonValidate.validateEmail(e.target.value)[0])setVe([true,e.target.value]); else setVe([false,e.target.value])}} />
                </div>

                <div className="field-set">
                    <div className="auth-inp-with-icon">
                        <input className={`auth-inp ${Vp[0]?"auth-inp-v":""}`} type={pOpen?"text":"password"} name="password" placeholder="Enter Password" value={Vp[1]} required onChange={(e)=>{if(XenonValidate.validatePassword(e.target.value)[0])setVp([true,e.target.value]); else setVp([false,e.target.value])}} id="pass-inp"/>
                        <button className="auth-inp-icon" onClick={()=>{togglePO(pOpen?false:true)}} type="button"><i className={`fa ${pOpen?"fa-eye-slash":"fa-eye"}`} style={{color:"var(--neutral100)"}}></i></button>
                    </div>
                    <p className="inp-pre">Atleast 8 characters (a combination of letters, numbers and symbols are optional)</p>
                </div>

                <div className="field-set">
                    <div className="auth-inp-with-icon">
                        <input className={`auth-inp ${Vrp[0]?"auth-inp-v":""}`} type={rpOpen?"text":"password"} name="password" placeholder="Confirm Password" value={Vrp[1]} required onChange={(e)=>{if(e.target.value == Vp[1])setVrp([true,e.target.value]); else setVrp([false,e.target.value])}} />
                        <button className="auth-inp-icon" onClick={()=>{togglerPO(rpOpen?false:true)}} type="button"><i className={`fa ${rpOpen?"fa-eye-slash":"fa-eye"}`} style={{color:"var(--neutral100)"}}></i></button>
                    </div>
                </div>
                <div className="field-set">
                    <input className={`auth-inp ${fn[1]?"auth-inp-v":""}`} placeholder="First Name" value={fn[0]} required onChange={(e)=>{if(XenonValidate.validateName(e.target.value)[0])_fn([e.target.value,true]); else _fn([e.target.value,false])}}/>
                </div>
                <div className="field-set">
                    <input className={`auth-inp ${ln[1]?"auth-inp-v":""}`} placeholder="Last Name" value={ln[0]} required onChange={(e)=>{if(XenonValidate.validateName(e.target.value)[0])_ln([e.target.value,true]); else _ln([e.target.value,false])}}/>
                </div>

                <div className="agree-cont">
                    <label htmlFor="agree" className="agree-container">
                        <input type="checkbox" className="check-bx" id="agree" name="agree" onChange={(e)=>{
                            agree(e.target.checked);
                        }}/>
                        <span className="checkmark"></span>
                    </label>
                    <span className="agree-txt">I agree with the <a href="">Terms and Conditions</a></span>
                </div>

                <button className="auth-submit" type="button" disabled={`${Vty[0]&&Ve[0]&&Vp[0]&&Vrp[0]&&fn[1]&&ln[1]&&agr?'':"disabled"}`} onClick={getOtp} >
                    {inLoaderActive? <InLoader /> : "Create Account"}
                </button>
            </form>

            <p className="reg-call">Already have an account? <Link to="/auth/login">Login</Link></p></>}

            {
                regProgress && <><div className="">
                <h1 className="auth-head">OTP Verification</h1>
                <p className="otp-pre">An OTP has been sent to<br/><span>{Ve[1]}</span> please provide it to<br/>continue registration </p>
                <form action="" className="form_otp">
                    {otp.map((data, i) => {
                        return (
                            <input maxLength='1' type="text" name="otp1" id="otp1" value={data} onChange={(e) => handleChange(e, i) } className="otp_input text-center" key={i}/>
                        );
                    })}
                </form>
                <div className="otp-timer-cont">
                    <button className="brdls bgls" id="re-otp" disabled={reOtpDisabled} onClick={getOtp}>Resend OTP:</button>
                    <span>{counter}</span>
                </div>
            </div>
    
            <div className="last_otp">
                <p>Wrong email address? Go back to make correction</p>
                <button className="button_otp" onClick={()=>{
                    regPrg(false)
                }}>Go back</button>
            </div></>
            }
        </main>
    );
};

export default Register;
