import { useState } from "react";
import { parseFigure } from "./global";


const StateHead = () =>{
    return(
        <p className="transaction-state-head">Transaction Details</p>
    )
}


const Main = (props) =>{
    const data = props.trans;
    const [transState, setTransState] = useState(data.status);
    
    return(
        <div className="withdraw-state-main">
            <div className="head">
                <p>&#x20A6;{parseFigure(data.amount)}</p>
                {transState == 0 && <p className="loss">FAILED</p>}
                {transState == 1 && <p className="pend">PENDING</p>}
                {transState == 2 && <p className="proft">SUCCESSFUL</p>}
                <p className="date neut">{data.date}</p>
            </div>
            <div className="body">
                <div>
                    <p>Transaction Type</p>
                    <p>{data.type}</p>
                </div>
                <div>
                    <p>Recipient Details</p>
                    <div>
                        <p>{data.RDet.name}</p>
                        <p>{data.RDet.bank} | {data.RDet.no}</p>
                    </div>
                </div>
                <div>
                    <p>Sender Details</p>
                    <div>
                        <p>{data.SDet.name}</p>
                        <p>{data.SDet.bank} | {data.SDet.no}</p>
                    </div>
                </div>
                <div>
                    <p>Transaction Reference</p>
                    <p>{props.id}</p>
                </div>
                <div>
                    <p>Session ID</p>
                    <p>{data.sId}</p>
                </div>
            </div>
        </div>
    )
}

const Body = (props) =>{
    return(
        <div id="withdraw-state-view">
            <StateHead />
            <Main trans={props.trans} id={props.id}/>
            <p className="end">
                Your transaction has been successfully processed. Note, however, that completion of any transfer may be affected by other factors including but not limited to transmission errors, incomplete information, fluctuations on the network/ internet, interruptions, glitch, delayed information or other matters beyond our control which may impact on the transaction and for which the we will not be liable. All transactions are subject to Guaranty Trust Bank confirmation and fraud proof verification.
            </p>
        </div>
    )
}

export {
    Body
}