import { parseFigure } from "./global";
import { Link } from "react-router-dom";
import parse from "html-react-parser";



const recents = {
    1 : {
        name : 'Fund Investment',
        amount : 150000,
        type : 'deb',
        date : "August 21, 11:04",
        status : 1
    },
    2 : {
        name : 'Wallet Top Up',
        amount : 250000,
        type : 'cred',
        date : "August 20, 11:04",
        status : 1
    },
    3 : {
        name : 'Investment Redeemed',
        amount : 20000,
        type : 'cred',
        date : "August 11, 11:04",
        status : 1
    },
    4 : {
        name : 'Wallet Withdrawal',
        amount : 10000,
        type : 'deb',
        date : "August 08, 11:04",
        status : 2
    },
    5 : {
        name : 'Swap',
        amount : 100000,
        type : 'deb',
        date : "August 01, 11:04",
        status : 0
    }
}

const TransItem = (props) =>{
    let stat = "";
    let cl = "";
    switch(recents[props.id].status){
        case 1:
            stat = "Successful";
            cl = "proft";
            break;
        case 0:
            stat = "Declined";
            cl = "loss";
            break;
        case 2:
            stat = "Pending";
            cl = "pend";
            break;
    }

    return(
        <div className="recent-item">
            <p className="name">{recents[props.id].name}</p>
            <p className="date">{recents[props.id].date}</p>
            <p className="amount">{recents[props.id].type == 'cred'?parse('&#x2B;')  : recents[props.id].type == 'deb'? parse('&#x2212;') :'' }{parseFigure(recents[props.id].amount)}</p>
            <p className={`${cl} stat`}>{stat}</p>
        </div>
    )

}
const RecentTransactions = () =>{
    return(
        <div id="recent-transaction-section">
            <div className="head">
                <span>Recent Transactions</span>
                <Link to="/app/dashboard/wallet" className="link">See all</Link>
            </div>
            <div className="body">
                <TransItem id="1"/>
                <TransItem id="2"/>
                <TransItem id="3"/>
                {/* <TransItem id="4"/> */}
                <TransItem id="5"/>
            </div>
        </div>
    );
}

export default RecentTransactions;