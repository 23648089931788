// import { UserModel } from "../global";


export const loginUser = (em,pas) =>{
    // if(em == 'account@vulue.org' && pas == '!24HKA.vulue'){
    //     sessionStorage.setItem('authKey','Hello you are amazing! --from vulue development team');
    //     let clientUser = new UserModel();
    //     sessionStorage.setItem('userProfile',JSON.stringify(clientUser.profile));
    //     // console.log(window.clientUser);
    //     return true;
    // } else return false;
}

export const checkAuthState = () =>{
    if(sessionStorage.getItem('authKey') && sessionStorage.getItem('vtim')){
        const key = sessionStorage.getItem('authKey');
        const left = parseInt(sessionStorage.getItem('vtim'));
        if(isNaN(left)) return false;
        if(left >= 86280000) return false;
        return key
    }
    return false;
}

