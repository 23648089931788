import Carousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { CompletedUnitForDetails } from "../../../../views/components/app/products/mtfProgressUnits";
import { ongoingUnits } from "./data";
import OvGr1 from '../../../../views/components/app/products/overviewGraph1';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { parseFigure } from '../../../../views/components/app/dashboard/global';

const Details = (prop) =>{
    const data = ongoingUnits[prop.id]
    return(
        <div className='unit-detail'>
            <div className='head'>
                <div className='meta1'>
                    <p><span>Total investment in this fund</span><span>Days till maturity</span></p>
                    <p><span>&#x20A6;{parseFigure(data.amount)}</span><span>{data.dur - data.Completed}</span></p>
                </div>
                <div className='meta2'>
                    <p><span>Net profit and loss (PNL):</span><span className='tag'>*Performance and returns reported are net of all fees.</span></p>
                    <p><span>&#x20A6;{parseFigure(data.ROI)}</span></p>
                </div> 
            </div>
            <div className='action'>
                <Link to="/products/MTF/invest">Invest</Link>
                <Link to={`/products/MTF/redeem?mtfid=${prop.id}`}>Redeem</Link>
            </div>
            <OvGr1/>
            <OvGr1/>
            <OvGr1/>
            <OvGr1/>
        </div>
    )
}


const OngoingUnitDetails = () =>{
    const query = new URLSearchParams(window.location.search);
    const unitId = query.get("prd");

    const pushIdToFront = (arr,id) =>{
        const index = arr.indexOf(id);
        if (index !== -1) {
            arr.splice(index, 1);
        }
        arr.unshift(id);
        return arr;
    }
    let _allUnits = [];
    for (const [k, v] of Object.entries(ongoingUnits)){
        if(v.status == 1) _allUnits.push(k)
    }
    _allUnits = pushIdToFront(_allUnits,unitId);
    var xdg = [];
    _allUnits.forEach(e => {
        xdg.push(<CompletedUnitForDetails id={e} own={e == unitId}/>);
    });


    return(
        <div id="main">
            <Carousel margin={15} items={1} className='mtf-selection-carousel' >
                {xdg}
            </Carousel>
            <Details id={unitId}/>
        </div>
    )
}

export default OngoingUnitDetails;