import { ongoingUnits } from "../../../../routes/app/products/MTF/data";
import { parseFigure } from "../dashboard/global";
import { ArrowUp, Lock } from "../../svgSymbolsr";

import { Link } from "react-router-dom";


const MtfProgBar = (prop) =>{
    return(
        <div className={`${prop.active && "active"} mtf-prog-bar`}></div>
    )
}

const MTFProgressGraph =(prop)=>{
    let Bars = [];
    let prog = parseInt(prop.prog*20/100);
    for(let i = 0;i<20;++i){
        Bars.push(<MtfProgBar active={prog > i? true : false}/>);
    }
    return(
        <div className="mtf-prog-illustration">
            <div><span>0</span><span>50</span><span>100</span></div>
            <div>
                {Bars}
            </div>
            
        </div>
    )
}
const OngoingUnit = (prop) =>{
    const data = ongoingUnits[prop.id]
    return(
        <Link className="ongoing-mtf mtf-unit" to={`/products/MTF/details?prd=${prop.id}`}>
            <p>
                <span className="amt">&#x20A6;{parseFigure(data.amount)}</span>
                <span className="proft">
                    <ArrowUp color="#23D223"/>
                    up {data.ROIToday} &#x25; today
                </span>
            </p>
            <p>Today's return <span className="proft">&#x20A6;{parseFigure(parseFloat((data.ROIToday*data.amount/100).toFixed(1)))}</span></p>
            <MTFProgressGraph prog={data.Completed*100/data.dur}/>
            <p className="annt proft">Unlocks at</p>
            <div className="proft meta">
                <p>{data.unlockDate}</p>
                <p><span>{(data.Completed*100/data.dur).toFixed(1)}&#x25; maturity</span> <Lock color="#23D223"/></p>
            </div>
        </Link>
    )
}

const ProcessingUnit = (prop) =>{
    const data = ongoingUnits[prop.id];
    return(
        <Link className="processing-mtf mtf-unit">
             <p>
                <span className="amt">&#x20A6;{parseFigure(data.amount)}</span>
                <span className="proft pend">PROCESSING</span>
            </p>
            <MTFProgressGraph prog={0}/>
            <p className="pend">{data.unlockDate}</p>
        </Link>
    )
}

const CompletedUnit = (prop) =>{
    const data = ongoingUnits[prop.id];
    return(
        <div className="completed-mtf mtf-unit">
            <p>&#x20A6;{parseFigure(data.amount)}</p>
            <p>Total earned <span className="proft">+{parseFigure(data.ROI)}</span></p>
            <MTFProgressGraph prog={100}/>
            <div className="action-cont">
                <p className="proft">100% maturity</p>
                <Link className="action-btn" to={`/products/MTF/redeem?rid=${prop.id}`}>Redeem</Link>
            </div>
        </div>
        
    )
}

const CompletedUnitForDetails = (prop) =>{
    const data = ongoingUnits[prop.id];
    return(
        <div className="ongoing-mtf mtf-unit">
            <p>
                <span className="amt">&#x20A6;{parseFigure(data.amount)}</span>
                <span className="proft">
                    <ArrowUp color="#23D223"/>
                    up {data.ROIToday} &#x25; today
                </span>
            </p>
            <p>Today's return <span className="proft">&#x20A6;{parseFigure(parseFloat((data.ROIToday*data.amount/100).toFixed(1)))}</span></p>
            <MTFProgressGraph prog={data.Completed*100/data.dur}/>
            <p className="annt proft">Unlocks at</p>
            <div className="proft meta">
                <p>{data.unlockDate}</p>
                <p><span>{(data.Completed*100/data.dur).toFixed(1)}&#x25; maturity</span> <Lock color="#23D223"/></p>
            </div>
            {!prop.own && <Link to={`/app/products/MTF/details?prd=${prop.id}`} className="mtf-unit-see-details">View Details</Link>}
        </div>
    )
}

export {OngoingUnit, ProcessingUnit, CompletedUnit, CompletedUnitForDetails}