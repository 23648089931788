import Input from "../../../views/components/Input";
import PlainInput from "../../../views/components/PlainInput";
import { Link } from "react-router-dom";
import SaveButton from "../../../views/components/SaveButton";


const EmpDev = ()=>{
    return(
        <div className="flex flex-col text-center align-items">
            <p style={{fontSize:'24px'}} className="font-bold  text-white pt-50">Employment Information</p>
            <div className="w-full special-text mt-5"><p>Employment Information</p></div>
            <Input name={'Company Name'}/>
            <Input name={'Monthly Salary'}/>
            <Input name={'Date Of Employment'}/>
            <Input name={'Salary Per Day'}/>
            <div className="w-full special-text mt-4"><p>Employment Information</p></div>
            <Input name={'Address Of Employer'}/>
            <PlainInput name={'Select State'} switch={false}/>
            <PlainInput name={'Select your employment type'} switch={false}/>
            <PlainInput name={'Select your industry'} switch={false}/>
            <SaveButton />


        </div>
    )
}
export default EmpDev;