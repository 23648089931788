
const TransC = (props) =>{
    return(
         
        <div className='text-whitey pl-2 mt-5 w-full-90 '>
                <div className='flex text-btw align-items'>
                    <p className='  '>{props.main}</p>
                    <span className="">{props.mainV}</span>
                </div>
                <div className='flex text-btw align-items size-m'>
                    <p className=' text-gray '>{props.subMain}</p>
                    <span className="text-green">{props.subMainV}</span>
                </div>
                <hr className='w-full ashlike mt-3'/>
        </div>
        
    )
}
export default TransC;