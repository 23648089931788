import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    currentToken: null,
    userDetails: {
        firstName: "",
        lastName: "",
        email: "",
        vl_id: undefined,
    },
    wallet: {
        balance: 0,
    },
    portfolio: {
        balance: 0,
    },
    cards: [],
    preferences: {
        language: "EN",
        watchlist: [],
    },
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        initUser: (state, action) => {
            const { details, wallet } = action.payload;
            state.userDetails = details;
            state.wallet = wallet;
        },
        setCurrentToken: (state, action) => {
            state.currentToken = action.payload.token;
        },
    },
});

export const { initUser, setCurrentToken } = userSlice.actions;

export default userSlice.reducer;
