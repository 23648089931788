import Header from "../../../views/components/home/header";
import {sortRoute} from "../../modules";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { emitViewChange, emitScreenViewMobile } from "../../../globals";

import Wallet from "./wallet";
import Cards from "./cards";
import Topup from "./topup";
import Withdraw from "./withdraw";
import Transfer from "./transfer";
import Market from "./watchlist";
import Error from "../../../views/components/error";


import { checkAuthState } from "../../../models/auth/script";



const Dashboard = () => {
    const navig = useNavigate();
    let headerLogic = false;

    const logedState = checkAuthState();
    if(!logedState) window.location.href = "/auth/login";
    useEffect(()=>{
        emitViewChange();
        emitScreenViewMobile();
    },[]);

    
    
    const { page } = useParams();
    console.log(page);
    headerLogic = (page == undefined || page == 'cards');
    let routes = {
        undefined: Wallet,
        'cards': Cards,
        'topup': Topup,
        'transfer': Transfer,
        'withdraw': Withdraw,
        'market' : Market
    };

    let Page = sortRoute(page, routes, Error);

    return (
        <>
            {headerLogic && <Header notification={true}/>}
            <Page />
        </>
    );
};

export default Dashboard;
