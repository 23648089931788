import Header from "../../views/components/headers";
import Error from "../../views/components/error";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {sortRoute} from "../modules";

import Login from './login';
import Register from './register';
import Kyc from './kyc';
import State from "./state";
import Verify from './verification';
import Status from './status';
import Doc from './documents';
import ResetPassword from "./resetPass";

import { emitViewChange } from "../../globals";



import { Overlay1, Overlay2 } from "../../views/components/overlay";
import StateDesign from "../../views/components/auth/stateDesign";



const AuthPage = () =>{
    useEffect(()=>{
        emitViewChange();
    },[])
    const {page} = useParams();
    let routes = {
        'login': Login,
        'register': Register,
        'state': State,
        'kyc': Kyc,
        'verification': Verify,
        'status': Status,
        'documents': Doc,
        'reset' : ResetPassword
    };

    let Page = sortRoute(page, routes, Error);

    let Ov1Logic = (page == 'login' ||page == 'register');
    let Ov2Logic = (page == 'state');
    let Ov3Logic = (page == 'verification');

    return(
        <div id="auth-base">
            {Ov1Logic && <Overlay1 topic={<>Take Your<br/>Wild Dreams<br/>Further<br/>with us!</>} end={<>Investment vehicles to match your needs.<br/>From individuals to entities.</>}/>}
            {Ov2Logic && <Overlay2 topic={<>Email Verification complete. Please complete your KYC<br/>verification in just a few steps.</>} content={<StateDesign />}/>}
            {Ov3Logic && <Overlay1 topic={<>Thanks for<br/>choosing to<br/>grow with<br/>US!</>} redir={['/dashboard/wallet','Go back to dashboard']}/>}
            <div className="auth-baseee">
                <Page />
            </div>
        </div>
    )
}

export default AuthPage;