import { data } from "jquery";
import Header from "../../../views/components/home/header";
import { Link } from "react-router-dom";



const products = {
    'p1' : {
        name : 'Vulue FX MTP',
        change : 1.3,
        trend : 'pos',
        det : 'Providing a reliable fund backed by managing derivatives, and structured similar to a fixed term investment portfolio. Lock-away your investments and earn up to 25%. More suited to investors with short term goals.',
        nameTag : 'MIRROR TRADING FUNDS',
        action : 'Quick Invest',
        icon : '/static/images/sprites/mtfs.png'
    },
    'p2' : {
        name : 'Vulue NGX 5',
        change : 0.62,
        trend : 'neg',
        det : 'Our top 10 picks organised in baskets for you to effortlessly invest primarily in various legacy funds. More suited to investors with conservative long term horizons.',
        nameTag : 'Equities Baskets',
        icon : '/static/images/sprites/eb.png'
    },
    'p3' : {
        name : 'Vulue Funds of Funds Series 10',
        change : 0.42,
        trend : 'neg',
        det : 'Our top 10 picks organised in baskets for you to effortlessly invest primarily in various legacy funds. More suited to investors with conservative long term horizons.',
        nameTag : 'Mutual Funds, Structured Notes and Absolute Return Funds',
        icon : '/static/images/sprites/fof.png'
    },
}


const SplashProductBanner = (prop) =>{
    const _data = products[prop.id]
    return(
        <div className="splash-product-banner">
            <div className="head">
                <p className="topic">{_data.name}</p>
                <p className="label">This Week</p>
            </div>
            <div className="trend">
                <span>{_data.change}%</span>
                {_data.trend =='pos'?<img src="/static/images/sprites/stocku.png" />:<img src="/static/images/sprites/stockd.png" />}
            </div>
            <p className="details">{_data.det}</p>
            <div className="base">
                <span className="tag">
                    <img src={_data.icon}/>
                    <span>{_data.nameTag}</span>
                </span>
                {_data.action && <Link to="" className="action">{_data.action}</Link>}
            </div>
        </div>
    )
}

const ProductSplashPage = ()=>{
    return(
        <div id="main">
            <Header />
            <main className="product-splash-main">
                <p className="product-splash-head">Investment vehicles that<br/>match your needs.</p>
                <div className="pds-content">
                    
                    <div className="splash-1">
                        <p className="head">Managed Solutions</p>
                        <p className="body">Diversification Made Simple!<br/><br/>For those who prefer a guided approach, our meticulously crafted strategies are designed to suit every risk appetite and investment goal. Let our experts do the heavy lifting, ensuring your investments are diversified strategically, so you can relax and watch your wealth grow.</p>
                    </div>
                    <div className="splash-slider">
                        <Link className="splash-2" to="./mtf/overview">
                            <SplashProductBanner id="p1"/>
                        </Link>
                        <div className="splash-3">
                            <SplashProductBanner id="p2" />
                        </div>
                        <div className="splash-4">
                            <SplashProductBanner id="p3" />
                        </div>
                    </div>
                    <div className="spawn spawn4">
                        <div className="spawn-banner-cont">
                            <img className="spawn-banner" src="/static/images/sprites/trxspwn.png"/>
                        </div>
                        <div className="main">
                            <p className="head">TRAILX<sup>TM</sup></p>
                            <p className="body">
                                Your Financial Horizon, Your Choice!
                            </p>
                            <p className="body">
                                Experience the freedom to craft your financial future, one investment at a time. We provide you with the tools, insights, and expertise to navigate the local market confidently. Whether you're an expert or a novice, our platform caters to your unique needs.<br/><br/>
                                Promoting a market-leading platform doesn't only mean providing data and computational power. It also means enabling ideas to shine. Allowing you to drastically improve your trading experience.<br/><br/>
                                We offer Low-latency data-driven market insights, signals and forecasts, backed by discretionary and Machine-Learning aided extensive investment research reporting as well as a strategy simulation environment.<br/><br/>
                                Centralised global data and market access across 20 countries with our integrated execution infrastructure supporting Equities, Fixed Income, Currencies and Commodities trading with CFDs, Futures, Options security-types supported.
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}


export default ProductSplashPage;