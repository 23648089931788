
import Error from "../../../error";
import { Body } from "../receipts";

const transactions = {
    "01234567890123456789" : {
        amount : 250000,
        status : 2,
        date : "29 Aug, 2023, 16:31",
        type : "Transfer to bank",
        RDet : {
            name : "UTANGE KEVIN AONDOKATOR",
            bank : "Guaranty Trust Bank",
            no : "0123456789"
        },
        SDet : {
            name : "MAX JOHN",
            bank : "Vulue",
            no : "9876543210"
        },
        sId : "123456789098765432123456789098"
    }
}



const State = () =>{
    const query = new URLSearchParams(window.location.search);
    const tRef = query.get("tref");
    
    const TranState =  transactions[tRef] ? Body : Error;
    return (
       <TranState trans={transactions[tRef]} id={tRef}/>
    )
}

export default State;