import { useSelector } from "react-redux";
import "../../styles/loader.css";

const Loader = ({isActive=false}) => {
    const loader = useSelector((state) => state.layout.loader);
    return (
        (isActive || loader.active) && (
            <div className={`vl-loader-container`}>
                <div className="vl-loader"></div>
            </div>
        )
    );
};

export default Loader;
