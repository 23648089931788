import React from 'react';
import { Link } from 'react-router-dom';

import '../../styles/settings.css';


const Button = (props)=>{
    return(
        <div className='w-full px-2' >
            <p className='text-white mb-b border-white customized-button'><Link className='text-white' to={props.link}>{props.name}</Link></p>
        </div>
    )
}
export default Button
