import NewPlain from "../../../views/components/app/settings/NewPlain";
import SmallDiv from "../../../views/components/app/settings/SmallDiv";

const Plain1 = ()=>{
    return(
        <div>
            <NewPlain />
            <div className=" mt-4 px-15">
                <div className="flex text-center a"></div>
                <SmallDiv name={'All Categories'} />
                <SmallDiv name={'Wallet Top Up'} />
                <SmallDiv name={'Wallet withdrawal'} />
                <SmallDiv name={'Investment funded'} />
                <SmallDiv name={'Investment Redeemed'} />
                <SmallDiv name={'Exchanged'} />
                

            </div>
        </div>
    )
}
export default Plain1;