


import { OpenInputs } from "../../../../views/components/app/dashboard/inputs";
import savedAccounts from "../../../../views/components/savedAcc";
import { useState } from "react";
import { Link } from "react-router-dom";

import { ongoingUnits } from "./data";
import { Overlay1 } from "../../../../views/components/overlay";
import Error from "../../../../views/components/error";

const Selection = () =>{

    const [select, setSelect] = useState(0);

    const data = savedAccounts[1];
    return(
        <div className="selection">
            
            <div className="">
                {(select == 0 || select == 1)&&<><p className="wtd-tf-dir">Redeem to Bank Account</p>
                <div id="saved-details-section">
                    <button className={`${select == 1 && 'active'} saved-acc-temp`} onClick={()=>{ setSelect(1)}}>
                        <div className="main">
                            <p className="acc-name">{data.name}</p>
                            <p className="remn">{data.no}<br/>{data.bank}</p>
                        </div> 
                        <div className="bank-icn">
                            <img src={data.imgUrl} alt="bankLogo" />
                        </div>
                    </button>
                </div></>}
                {(select == 0) && <><div className="alt-bank-acc-slct">
                    <button onClick={()=>setSelect(2)}>Use another bank account?</button>
                    <p><span></span><strong>OR</strong><span></span></p>
                </div>
                <div>
                    <Link className="link-as-submit">Redeem to wallet</Link>
                </div>
                
                </>}
                {select==2&&<div className="acc-form">
                    <p>Provide account details</p>

                    <OpenInputs type="number" placeholder="Account Number"/>
                    <OpenInputs type="text" placeholder="Bank Name"/>
                </div>}
                <div className="end-act">
                    <p className="info-txt">Please ensure that the details are correct as any loss of funds will not be covered by Vulue.</p>
                    {(select==1 || select==2)&&<Link className="link-as-submit" to="/products/MTF/state?tref=01234567890123456789">Confirm</Link>}
                    {select == 1 && <button onClick={()=>setSelect(0)}>Go Back</button>}
                </div>
            </div>
            
        </div>
    )
}

const Redeem = () =>{
    const query = new URLSearchParams(window.location.search);
    const rId = query.get("rid");
    
    let data,id;

    for(const [k,v] of Object.entries(ongoingUnits)){
        if(rId == k) {
            data = v;
            id = k;
        }   
    }

    if(data){
        return(
            <div id="auth-base">
                <Overlay1 topic={<>Thanks for<br/>choosing to<br/>grow with<br/>US!</>} redir={['/dashboard/wallet',"Go back to dashboard"]}/>
                <div id="main" className="mtf-redeem-main">
                    <div className="head">Proceed to?</div>
                    <div className="pre">
                        <p className="amt-inp-cont"><span>&#x20A6;</span><input className="open-inputs" type="number" value={data.amount} disabled/></p>
                        <p className="inf">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_319_2594)">
                                    <path d="M5.625 13.3438H3.75C2.9212 13.3438 2.12634 12.998 1.54029 12.3827C0.954242 11.7674 0.625 10.9328 0.625 10.0625C0.625 9.19223 0.954242 8.35766 1.54029 7.74231C2.12634 7.12695 2.9212 6.78125 3.75 6.78125H5.625V13.3438Z" stroke="#EBF0FF" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.625 13.3438C9.29258 13.3441 12.8782 14.484 15.93 16.6197L16.875 17.2812V2.84375L15.93 3.50525C12.8782 5.64107 9.29258 6.78093 5.625 6.78125V13.3438Z" stroke="#EBF0FF" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M19.375 8.75V11.375" stroke="#EBF0FF" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.625 13.3438C5.62447 14.2029 5.79042 15.0532 6.11276 15.843C6.4351 16.6327 6.90708 17.3454 7.5 17.9375" stroke="#EBF0FF" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_319_2594">
                                        <rect width="20" height="21" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span>Both capital and returns must be redeemed and withdrawn.</span>
                        </p>
                    </div>
                    <Selection />
                </div>
            </div>
        )
    } 
    return(
        <div id="main">
            <Error />
        </div>
    )
    
}
export default Redeem;