const AccState = ()=>{
    return(
        <div className=" w-full ">
            <div className="text-center flex align-items"><p className="text-whitey mt-5 w-full font-bold pl-6 mb-2">Account Statement</p></div>
            
            <div className="w-full flex align-items text-center  pt-2">
                <p className="  h-full  w-full-85 purplish rounded-sm text-whitey  flex text-center align-items mt-7"> Transaction History </p>
            </div>           

            <div className="w-full flex align-items text-center ">
                <p className="  h-full  w-full-85 purplish rounded-sm text-whitey flex text-center align-items mt-7">Request Account Statement Document </p>
            </div>

        </div>
    )
}
export default AccState;