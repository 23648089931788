const sortRoute = (p,obj,err) =>{
    return p in obj? obj[p]: err;
}
const copyContent = async ( _t) => {
    try {
      await navigator.clipboard.writeText(_t);
    //   console.log('Content copied to clipboard');
    } catch (err) {
    //   console.error('Failed to copy: ', err);
    }
}

export {sortRoute, copyContent};